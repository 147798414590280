import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { SvgImage } from 'components/SvgImage'

import animationConfig from 'assets/animations/heart-animation.json'
import bloodPressureLine from 'assets/images/sprite/blood-pressure-line.svg'
import effectivenessLine from 'assets/images/sprite/effectiveness-line.svg'
import ldlLine from 'assets/images/sprite/ldl-line.svg'

import { StyledSocialProofVariant2 as S } from './SocialProofVariant2.styles'

export const SocialProofVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        name: 'heartAnimation',
        container: animationContainerRef.current,
        animationData: animationConfig,
      })
    }

    return () => lottie.destroy('heartAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Measurements>
          <S.AnimationContainer ref={animationContainerRef} />
          <S.Measurement lineBottom={-70}>
            <Trans i18nKey="onboarding.socialProofHeartAnimation.pressure" />
            <SvgImage svg={bloodPressureLine} width={178} />
          </S.Measurement>

          <S.Measurement lineBottom={-8}>
            {t`onboarding.socialProofHeartAnimation.ldlValue`}
            <Trans i18nKey="onboarding.socialProofHeartAnimation.ldlUnits" />
            <SvgImage svg={ldlLine} width={180} />
          </S.Measurement>

          <S.Measurement lineBottom={-8}>
            <Trans i18nKey="onboarding.socialProofHeartAnimation.effectiveness" />
            <SvgImage svg={effectivenessLine} width={193} />
          </S.Measurement>
        </S.Measurements>

        <S.Text>
          <Trans i18nKey="onboarding.socialProofHeartAnimation.text" />
        </S.Text>

        <S.StickyButtonContainer>
          <Button
            type="button"
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
