import { useDispatch, useSelector } from 'react-redux'

import {
  sendFacebookParamsAction,
  sendGoogleAdsParamsAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

export const useFirstPageFinish = (): (() => void) => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  return () => {
    dispatch(sendFacebookParamsAction())
    dispatch(sendGoogleAdsParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
  }
}
