import React from 'react'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { CheckboxCustomValue } from 'root-constants/common'

import { StyledDynamicLeftToRight as S } from './DynamicLeftToRight.styles'

export const DynamicLeftToRight: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <S.Column>
        {!!title && (
          <S.Title marginBottom={subtitle ? 12 : 24}>{title}</S.Title>
        )}
        {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        {optionType === OptionType.RADIO && (
          <StyledOption.OptionsContainer>
            {optionData?.map(({ value, text, id }) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                disabled={isAnswersDisabled}
              >
                <QuestionButton>{!!text && text}</QuestionButton>
              </Option>
            ))}
          </StyledOption.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <StyledOption.OptionsContainer paddingBottom={40}>
            {optionData?.map(({ id, value, text }) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                checked={answers.includes(value)}
              >
                {value === CheckboxCustomValue.NONE_OF_THE_ABOVE ? (
                  <QuestionButton data-has-none-button-check-icon>
                    {!!text && text}
                  </QuestionButton>
                ) : (
                  <QuestionButton data-has-check-icon>
                    {!!text && text}
                  </QuestionButton>
                )}
              </Option>
            ))}
          </StyledOption.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <StickyButtonContainer>
            <Button
              type="button"
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {!!buttonText && buttonText}
            </Button>
          </StickyButtonContainer>
        )}
      </S.Column>
    </S.Wrapper>
  )
}
