import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledMainGoalVariant6 as S } from './MainGoalVariant6.styles'
import { OPTION_VALUES_MAIN_GOAL_6, PAGE_NAME } from './constants'

export const MainGoalVariant6: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: CustomPageId.MAIN_GOAL,
    pageName: PAGE_NAME,
    question: t('onboarding.mainGoal.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: CustomPageId.MAIN_GOAL,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      }
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.mainGoal.title2`}</S.Title>
        <S.Text>{t`onboarding.additionalGoals.chooseAll`}</S.Text>
        <S.OptionsContainer paddingBottom={23}>
          {OPTION_VALUES_MAIN_GOAL_6.map(({ value, text }) => (
            <AnswerWithCheckbox
              {...optionProps}
              theme={answerWithCheckboxTheme.CARDIMATE}
              value={value}
              key={value}
              checked={answers.includes(value)}
              iconSrc={checkIcon}
              margin="0 0 12px"
            >
              {t(text)}
            </AnswerWithCheckbox>
          ))}
        </S.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
