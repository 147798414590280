import React from 'react'

import {
  AnswerWithIconAndCheckbox,
  Button,
  answerWithIconAndCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { DynamicLeftToRightIconVariant1 as S } from './DynamicLeftToRightIconVariant1.styles'

export const DynamicLeftToRightIconVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <S.Column>
        {!!title && <S.Title marginBottom={subtitle ? 8 : 24}>{title}</S.Title>}
        {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

        {optionType === OptionType.CHECKBOX && (
          <StyledOption.OptionsContainer>
            {optionData?.map(({ id, value, text, imageUrl }) => (
              <AnswerWithIconAndCheckbox
                {...optionProps}
                theme={answerWithIconAndCheckboxTheme.CARDIMATE}
                value={value}
                key={id}
                checked={answers.includes(value)}
                iconSrc={checkIcon}
                imageSrc={imageUrl}
                margin="0 0 12px"
              >
                {!!text && text}
              </AnswerWithIconAndCheckbox>
            ))}
          </StyledOption.OptionsContainer>
        )}
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
            theme={buttonTheme.CARDIMATE}
          >
            {!!buttonText && buttonText}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
