export const DYNAMIC_DISCOUNT_CONTAINER_THEME = {
  red: 'linear-gradient(87deg, rgba(196, 2, 2, 0.15) -4.33%, rgba(255, 86, 89, 0.15) 111.17%)',
  blue: 'linear-gradient(87deg, rgba(123, 0, 255, 0.15) -3.7%, rgba(27, 228, 255, 0.15) 119.47%)',
  orange:
    'linear-gradient(88deg, rgba(255, 0, 49, 0.15) -0.53%, rgba(255, 190, 27, 0.15) 108.08%)',
}
export const DYNAMIC_DISCOUNT_TITLE_COLORS = {
  red: '#E74C3C',
  blue: '#0091FF',
  orange: '#F83B00',
}
