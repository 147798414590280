import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledTrialAmount = {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #b2b8cd;

    strong {
      font-weight: 800;
    }
  `,
  IntroOfferContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    font-size: 16px;
    line-height: 20px;

    strong {
      font-weight: 800;
    }
  `,
  Title: styled.h2`
    font-size: 22px;
    font-weight: 900;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.secondaryText};
    margin-bottom: 8px;
    text-align: center;
  `,
  Description: styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #7b8898;
  `,
  ExtraBonus: styled.p`
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;

    strong {
      color: ${Color.RED};
    }
  `,
}
