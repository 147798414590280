import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledPaymentSummary as S } from './PaymentSummary.styles'

const PERIOD_NAMES = {
  day: 'payment.planBlock.day',
  week: 'payment.planBlock.week',
  month: 'payment.planBlock.month',
  year: 'payment.planBlock.year',
}

export const PaymentSummary: React.FC = () => {
  const { t } = useTranslation()

  const { currency, currentPrice, oldPrice, periodName, periodQuantity } =
    usePurchaseStore()

  return (
    <S.Wrapper>
      <S.Container>
        <S.PriceContainer>
          <span>
            {t('payment.paymentSummary.planDescription', {
              periodQuantity,
              periodName: t(PERIOD_NAMES[periodName]),
            })}
          </span>
          <span>
            {CURRENCY_SYMBOLS[currency]}
            {oldPrice.fullPrice}
          </span>
        </S.PriceContainer>
        <S.PriceContainer>
          <span>
            {t('payment.paymentSummary.discount', {
              discountAmount: oldPrice.percentOfDiscount,
            })}
          </span>
          <em>
            -{CURRENCY_SYMBOLS[currency]}
            {oldPrice.amountOfDiscount}
          </em>
        </S.PriceContainer>
        <S.PriceContainer>
          <strong>{t`payment.paymentSummary.total`}</strong>
          <strong>
            {CURRENCY_SYMBOLS[currency]}
            {currentPrice}
          </strong>
        </S.PriceContainer>
      </S.Container>
    </S.Wrapper>
  )
}
