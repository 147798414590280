import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { TAnswer } from 'models/common.model'

import {
  CheckboxCustomValue,
  GENDER_PAGES,
  Gender,
} from 'root-constants/common'
import { MAIN_GOAL_OPTION_VALUES } from 'root-constants/options'
import { CustomPageId, PageId } from 'root-constants/pages'

export const useUserData = (): {
  age: number
  weight: number
  weightDescription: string
  weightUnit: TAnswer
  height: string | string[]
  userGender: string
  isMale: boolean
  ethnicity: string
  goal: string
  prioritizedUserGoal: string
  symptoms: string[] | null
  additionalGoals: string[]
  activity: string
  name: string
  bloodPressureFrequency: string
  userGoals: string[]
  alcoholQuantity: string
  menopause: string
} => {
  const answers = useSelector(selectAnswers)

  const age = answers?.[CustomPageId.AGE] || 0

  const userGender = useMemo(() => {
    const genderPageId = GENDER_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[genderPageId] as string
  }, [answers])

  const isMale = userGender === Gender.MALE

  const weight = answers?.[PageId.WEIGHT_1] || 0

  const weightDescription = (answers?.[PageId.WEIGHT_3] as string) || ''

  const weightUnit = answers?.weightUnit || ''

  const height = answers?.[PageId.HEIGHT_1] || ''

  const ethnicity = (answers?.[PageId.ETHNICITY_1] as string) || ''

  const goal = answers?.[CustomPageId.MAIN_GOAL] as string

  const userGoals = useMemo(
    () => (answers?.[CustomPageId.MAIN_GOAL] as string[]) || [],
    [answers],
  )

  const alcoholQuantity = (answers?.[PageId.ALCOHOL_2] as string) || ''

  const menopause = (answers?.[PageId.MENOPAUSE_1] as string) || ''

  const formattedUserGoals = useMemo(() => {
    if (
      userGoals.length === 1 &&
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.OTHER)
    ) {
      return MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH
    }
    if (
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.OTHER) &&
      userGoals.includes(MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH)
    ) {
      return MAIN_GOAL_OPTION_VALUES.CONTROL_HEART_HEALTH
    }
    return userGoals
  }, [userGoals])

  const [prioritizedUserGoal] = useMemo(() => {
    return Object.values(MAIN_GOAL_OPTION_VALUES).filter((item) =>
      formattedUserGoals.includes(item),
    )
  }, [formattedUserGoals])

  const symptoms = useMemo(() => {
    const userSymptoms = (answers?.[CustomPageId.SYMPTOMS] as string[]) || []
    if (userSymptoms.includes(CheckboxCustomValue.NONE_OF_THE_ABOVE)) {
      return null
    }
    return userSymptoms
  }, [answers])

  const additionalGoals =
    ((answers?.[PageId.ADDITIONAL_GOALS_1] ||
      answers?.[PageId.ADDITIONAL_GOALS_2]) as string[]) || []

  const activity =
    (answers?.[PageId.PHYSICAL_ACTIVITY_2] as string) ||
    (answers?.[PageId.PHYSICAL_ACTIVITY_4] as string) ||
    ''

  const name = (answers?.[CustomPageId.NAME] as string) || ''

  const bloodPressureFrequency =
    (answers?.[PageId.MONITOR_BLOOD_PRESSURE_3] as string) || ''

  return {
    age: +age,
    userGender,
    isMale,
    weight: +weight,
    weightDescription,
    weightUnit,
    height,
    ethnicity,
    goal,
    prioritizedUserGoal,
    symptoms,
    additionalGoals,
    activity,
    name,
    bloodPressureFrequency,
    userGoals,
    alcoholQuantity,
    menopause,
  }
}
