import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'

import ratingStars from 'assets/images/rating-stars.svg'

import { StyledReviewsSlider as S } from './ReviewSlider.styles'

type TProps = {
  marginBottom?: number
}

export const ReviewsSliderDynamic: React.FC<TProps> = ({
  marginBottom = 24,
}) => {
  const { customerReviews, customerReviewsTitle } = useDynamicPaywallConfig()

  return (
    <S.Wrapper marginBottom={marginBottom}>
      {!!customerReviewsTitle && <S.Title>{customerReviewsTitle}</S.Title>}
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        breakpoints={{
          1200: {
            spaceBetween: 12,
            initialSlide: 1,
          },
        }}
      >
        {customerReviews?.map(({ id, title, author, imageUrl, review }) => (
          <SwiperSlide key={id}>
            <S.Card>
              <S.CardHeader>
                <S.Avatar
                  width="40"
                  height="40"
                  src={imageUrl}
                  loading="lazy"
                />
                <div>
                  <img
                    height="15"
                    src={ratingStars}
                    alt="rating 5 stars"
                    loading="lazy"
                  />
                  <S.PersonDescription>
                    {!!author && author}
                  </S.PersonDescription>
                </div>
              </S.CardHeader>
              <S.CardTitle>{!!title && title}</S.CardTitle>
              <S.CardText>{!!review && review}</S.CardText>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
