import { SLEEP_OPTION_VALUES } from 'root-constants/options'

export const OPTION_VALUES = [
  { value: SLEEP_OPTION_VALUES.LESS_THAN_6, text: 'onboarding.sleep.lessThan' },
  { value: SLEEP_OPTION_VALUES.AVERAGE, text: 'onboarding.sleep.average' },
  { value: SLEEP_OPTION_VALUES.MORE_THAN_8, text: 'onboarding.sleep.moreThan' },
  { value: SLEEP_OPTION_VALUES.VARIES, text: 'onboarding.sleep.varies' },
]
export const TITLE_PATH = 'onboarding.sleep.title2'
export const DYNAMIC_TITLE_OPTIONS = {
  daily: 'onboarding.sleep.daily',
  several_times: 'onboarding.sleep.several',
  occasionally: 'onboarding.sleep.occasionally',
  never: 'onboarding.sleep.never',
}
