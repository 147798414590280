import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { StyledHeartDiseaseVariant1 as S } from './HeartDiseaseVariant1.styles'
import { OPTION_VALUES } from './constants'

export const HeartDiseaseVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.heartDisease.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.heartDisease.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PARENTS_SIBLINGS}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>
              <Trans i18nKey="onboarding.heartDisease.immediateMembers" />
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GRANDPARENTS}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t`onboarding.heartDisease.extendedMembers`}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_HISTORY}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t`onboarding.heartDisease.noHistory`}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.UNSURE}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t`onboarding.heartDisease.unsure`}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
