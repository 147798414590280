import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { convertSnakeCaseToReadableText } from 'helpers/convertSnakeCaseToReadableText'

const HEADLINE_QUERY_PARAM = 'headline'

export const useURLTitle = () => {
  const { search } = useLocation()

  const urlParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search)
    return Object.fromEntries(urlSearchParams.entries())
  }, [search])

  return useMemo(() => {
    const headline = urlParams[HEADLINE_QUERY_PARAM]
    if (!headline) return ''

    return convertSnakeCaseToReadableText(headline)
  }, [urlParams])
}
