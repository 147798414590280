import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useIsCurrentCohort } from 'hooks/common/useIsCurrentCohort'
import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Cohort } from 'root-constants/common'

import { StyledHeartDiseaseVariant3 as S } from './HeartDiseaseVariant3.styles'
import { OPTION_VALUES } from './constants'

export const HeartDiseaseVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const { isMale } = useUserData()
  const isRendered = useIsCurrentCohort(Cohort.CARDIMATE_19_FEED)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.heartDisease.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      }
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        {isRendered && (
          <S.Title>
            {t(
              isMale
                ? 'onboarding.heartDisease.menFace'
                : 'onboarding.heartDisease.uniqueHeart',
            )}
          </S.Title>
        )}
        <S.Title>{t`onboarding.heartDisease.title`}</S.Title>
        <S.Subtitle>{t`onboarding.heartDisease.subtitle`}</S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PARENTS_SIBLINGS}
            checked={answers.includes(OPTION_VALUES.PARENTS_SIBLINGS)}
          >
            <QuestionButton data-has-check-icon>
              <Trans i18nKey="onboarding.heartDisease.parentsSiblings" />
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GRANDPARENTS}
            checked={answers.includes(OPTION_VALUES.GRANDPARENTS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.grandParents`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_HISTORY}
            checked={answers.includes(OPTION_VALUES.NO_HISTORY)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.noHistory`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.UNSURE}
            checked={answers.includes(OPTION_VALUES.UNSURE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.heartDisease.unsure`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
