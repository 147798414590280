import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import dayjs from 'dayjs'

import { selectAnswers } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import scale from 'assets/images/summary-pressure-scale.png'

import { goTo } from 'browser-history'
import { BLOOD_PRESSURE_OPTION_VALUES } from 'root-constants/options'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledSummaryPressureAnalyses as S } from './SummaryPressureAnalyses.styles'
import { BLOOD_PRESSURE_CONTEXT } from './constants'

export const SummaryPressureAnalyses: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const userAnswers = useSelector(selectAnswers)
  const bloodPressure = userAnswers?.[CustomPageId.BLOOD_PRESSURE] as string

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: PageId.SUMMARY_HEART_SCORE, search })
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="summary.summaryPressureAnalyses.title" />
        </S.Title>

        <S.Card>
          <S.CardTitle>
            {t('summary.summaryPressureAnalyses.pressure', {
              context:
                BLOOD_PRESSURE_CONTEXT[bloodPressure] ||
                BLOOD_PRESSURE_CONTEXT[
                  BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80
                ],
            })}
          </S.CardTitle>

          <S.PressureWrapper>
            <S.PressureValue>
              {t('summary.summaryPressureAnalyses.pressureValue', {
                context:
                  BLOOD_PRESSURE_CONTEXT[bloodPressure] ||
                  BLOOD_PRESSURE_CONTEXT[
                    BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80
                  ],
              })}
            </S.PressureValue>
            <S.PressureUnits>{t`summary.summaryPressureAnalyses.pressureUnits`}</S.PressureUnits>
          </S.PressureWrapper>
          <S.Date>
            {t('summary.byDate', {
              date: dayjs().add(2, 'month').format('MMM D, YYYY'),
            })}
          </S.Date>
          <S.ImageContainer>
            <img src={scale} alt="scale" />
          </S.ImageContainer>
        </S.Card>

        <S.ListTitle>
          {t`summary.summaryPressureAnalyses.cardiPlan`}
        </S.ListTitle>
        <S.List>
          {(
            t('summary.summaryPressureAnalyses.benefits', {
              returnObjects: true,
            }) as string[]
          ).map((text) => (
            <S.ListItem key={text}>
              <S.CheckIcon />
              <span>{text}</span>
            </S.ListItem>
          ))}
        </S.List>

        <StickyButtonContainer>
          <Button type="button" onClick={handleContinue}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}
