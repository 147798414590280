import { Cohort } from 'root-constants/common'

export const PAGE_NAME = 'Preparing Personal Flow'

export const PROCESSING_TEXTS = [
  'onboarding.processing.reviewingData',
  'onboarding.processing.heartHealth',
  'onboarding.processing.healthRisks',
  'onboarding.processing.training',
  'onboarding.processing.fineTuning',
  'onboarding.processing.healthReport',
]

export const PROCESSING_TEXT_FOUR_POINTS = [
  'onboarding.processing.reviewingData',
  'onboarding.processing.heartHealth',
  'onboarding.processing.healthRisks',
  'onboarding.processing.training',
]

export const COHORTS_WITH_SENIOR_FLOW: string[] = [
  Cohort.CARDIMATE_11,
  Cohort.CARDIMATE_14,
]
