import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import pulseImg from 'assets/images/pulse.png'

import { StyledDidYouKnow as S } from './DidYouKnow.styles'

const PAGE_NAME = 'App info 1 page'

export const DidYouKnowVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.didYouKnow.title`}</S.Title>
        <S.Text>
          <Trans i18nKey="onboarding.didYouKnow.description" />
        </S.Text>
        <S.ImageContainer>
          <img src={pulseImg} alt="pulse" />
        </S.ImageContainer>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
