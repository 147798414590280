import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getUserStatusAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID, selectUserPaymentSystem } from 'root-redux/selects/user'

import { setIsPaymentProcessingPopupActiveAction } from 'modules/payment/redux/actions/common'
import { selectPaymentMethod } from 'modules/payment/redux/selects'

import successIcon from 'assets/images/success.svg'

import { StyledPaymentStatus as S } from 'common-styles'
import { Language, PaymentMethod, PaymentSystem } from 'root-constants/common'

export const PaymentSuccess: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const language = useSelector(selectLanguage)

  const hasPayPalDisclaimer = useMemo(
    () =>
      paymentMethod === PaymentMethod.PAYPAL &&
      paymentSystem !== PaymentSystem.PRIMER,
    [paymentMethod, paymentSystem],
  )

  useEffect(() => {
    return () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  const handleSuccessButtonClick = () => {
    dispatch(getUserStatusAction(uuid))
    dispatch(setIsPaymentProcessingPopupActiveAction(false))
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Image src={successIcon} width="72" alt="Success" />
        <S.Title>{t`payment.paymentWaitingModal.successful`}</S.Title>
        <S.Subtitle>{t`payment.paymentWaitingModal.thankYou`}</S.Subtitle>
        <S.Button
          type="button"
          onClick={handleSuccessButtonClick}
          hasSmallerFontSize={language === Language.PT}
        >
          {t`payment.paymentWaitingModal.proceedToMyPlan`}
        </S.Button>
        {hasPayPalDisclaimer && (
          <S.Disclaimer>
            {t`payment.paymentWaitingModal.paypalDisclaimer`}
          </S.Disclaimer>
        )}
      </S.Container>
    </S.Wrapper>
  )
}
