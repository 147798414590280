import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { resetErrorAction } from 'root-redux/actions/common'

import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useUserData } from 'hooks/user/useUserData'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PrimerPaymentForm } from 'modules/payment/components/PrimerPaymentForm'
import { TotalAmount } from 'modules/payment/components/TotalAmount'
import { COUNTRIES_WITHOUT_PAYPAL } from 'modules/payment/constants'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack } from 'browser-history'

import { Description } from '../Description'
import { Divider } from '../Divider'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import { TotalAmountVAT } from '../TotalAmountVAT'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows2: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { cohort, screenName, countryCode } = usePurchaseStore()
  const { goal } = useUserData()
  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()

  const hasPaypal = !COUNTRIES_WITHOUT_PAYPAL.includes(
    countryCode.toLowerCase(),
  )

  const handleBack = () => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })

    goBack()
  }

  return (
    <>
      <FeaturesReady timeout={0} fallback={<div />}>
        <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>
        {hasIncludedVAT ? <TotalAmountVAT /> : <TotalAmount />}
      </FeaturesReady>
      <S.Form>
        <StripePaymentForm />
      </S.Form>
      {hasPaypal && (
        <>
          <Divider />
          <PrimerPaymentForm />
        </>
      )}
      <PaymentRequestButton isDividerVisible={!hasPaypal} />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
        hasMarginTop={!hasPaypal}
      />
      <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
      <S.BackButton onClick={handleBack}>
        {t`payment.paymentFlow.back`}
      </S.BackButton>
      <Description />
    </>
  )
}
