import { COMMON_OPTION_VALUES } from 'root-constants/options'

export const OPTION_VALUES = {
  POST_MENOPAUSE: 'post_menopause',
}

export const OPTIONS_DATA = [
  { value: COMMON_OPTION_VALUES.YES, text: 'actions.yes' },
  { value: COMMON_OPTION_VALUES.NO, text: 'actions.no' },
  {
    value: OPTION_VALUES.POST_MENOPAUSE,
    text: 'onboarding.menopause.postMenopause',
  },
  {
    value: COMMON_OPTION_VALUES.PREFER_NOT_TO_ANSWER,
    text: 'actions.notToAnswer',
  },
]
