import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIsInContextShownAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { StyledLanguageSelector as S } from './InContextPopup.styles'

export const InContextPopup = () => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)
  const [isMenuShown, setIsMenuShown] = useState(false)
  const status = isInContextShown ? 'enabled' : 'disabled'

  const handleChangeInContextVisibility = () => {
    dispatch(setIsInContextShownAction(true))
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'C') {
      setIsMenuShown((prevState) => !prevState)
    }
  }

  const handleHide = () => {
    setIsMenuShown(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return isMenuShown ? (
    <S.Overlay onClick={handleHide}>
      <S.Wrapper>
        <S.Title>Crowdin In-Context</S.Title>
        <S.StatusText isEnabled={isInContextShown}>
          Status: <strong>{status}</strong>
        </S.StatusText>
        {!isInContextShown && (
          <S.Button
            isEnabled={isInContextShown}
            type="button"
            onClick={handleChangeInContextVisibility}
          >
            Enable
          </S.Button>
        )}
      </S.Wrapper>
    </S.Overlay>
  ) : null
}
