import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'

import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useUserData } from 'hooks/user/useUserData'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { COUNTRIES_WITHOUT_PAYPAL } from 'modules/payment/constants'

import { GlobalStatusBar } from 'components/GlobalStatusBar'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack } from 'browser-history'

import { Description } from '../Description'
import { Divider } from '../Divider'
import { PayPalContainer } from '../PayPalContainer'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { PaymentSummary } from '../PaymentSummary'
import { StripePaymentForm } from '../StripePaymentForm'
import { TrialAmount } from '../TrialAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { cohort, trialPriceId, screenName, countryCode } = usePurchaseStore()
  const { goal } = useUserData()
  const productId = useProductId()

  const hasPaypal = !COUNTRIES_WITHOUT_PAYPAL.includes(
    countryCode.toLowerCase(),
  )

  const handleBack = () => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })

    goBack()
  }

  return (
    <>
      <GlobalStatusBar completedStageNumber={1} marginTop={24} />
      {trialPriceId ? <TrialAmount /> : <PaymentSummary />}
      <S.Form>
        <StripePaymentForm />
      </S.Form>
      {hasPaypal && (
        <>
          <Divider />
          <PayPalContainer />
        </>
      )}
      <PaymentRequestButton isDividerVisible={!hasPaypal} />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
        hasMarginTop={!hasPaypal}
      />
      <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
      <S.BackButton onClick={handleBack}>
        {t`payment.paymentFlow.back`}
      </S.BackButton>
      <Description />
    </>
  )
}
