import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useInputValidation } from 'hooks/common/useInputValidation'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Popover } from 'components/Popover'

import { goTo } from 'browser-history'
import { Language } from 'root-constants/common'

import { StyledWeight as S } from './Weight.styles'
import {
  PAGE_NAME,
  WEIGHT_KG_MAX,
  WEIGHT_KG_MIN,
  WEIGHT_LB_MAX,
  WEIGHT_LB_MIN,
} from './constants'

export const WeightVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { height } = useUserData()

  const isImperialUsed = Array.isArray(height)

  const {
    value: kg,
    isValid: isKgValid,
    handleInputChange: handleKgChange,
  } = useInputValidation()

  const {
    value: lb,
    isValid: isLbValid,
    handleInputChange: handleLbChange,
  } = useInputValidation()

  const isSubmitButtonDisabled = !(isImperialUsed
    ? isLbValid && lb
    : isKgValid && kg)

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(
      setMultipleAnswerAction({
        answers: {
          [pageId]: isImperialUsed ? +lb : +kg,
          weightUnit: isImperialUsed ? 'lb' : 'kg',
        },
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.weight.title', { lng: Language.EN }),
      pageName: PAGE_NAME,
      answers: isImperialUsed ? `${lb} lb` : `${kg} kg`,
    })
    goTo(nextPagePath)
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.weight.title`}</S.Title>
        <S.ButtonsWrapper>
          <S.SystemButton>
            {isImperialUsed
              ? t`commonComponents.imperial`
              : t`commonComponents.metric`}
          </S.SystemButton>
        </S.ButtonsWrapper>

        <form onSubmit={handleSubmit}>
          {isImperialUsed && (
            <S.SystemWrapper>
              <Popover
                isShown={!isLbValid}
                position="center"
                text={t('commonComponents.inputValidationText', {
                  minValue: WEIGHT_LB_MIN,
                  maxValue: WEIGHT_LB_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.pounds`}
                  min={WEIGHT_LB_MIN}
                  max={WEIGHT_LB_MAX}
                  onChange={(e) => {
                    handleLbChange(e.target.value, e.target.validity.valid)
                  }}
                  isValid={isLbValid}
                  inputMode="numeric"
                />
              </Popover>
            </S.SystemWrapper>
          )}

          {!isImperialUsed && (
            <S.SystemWrapper>
              <Popover
                isShown={!isKgValid}
                position="center"
                text={t('commonComponents.inputValidationText', {
                  minValue: WEIGHT_KG_MIN,
                  maxValue: WEIGHT_KG_MAX,
                })}
              >
                <Input
                  type="number"
                  placeholder={t`commonComponents.kilograms`}
                  min={WEIGHT_KG_MIN}
                  max={WEIGHT_KG_MAX}
                  onChange={(e) => {
                    handleKgChange(e.target.value, e.target.validity.valid)
                  }}
                  isValid={isKgValid}
                  inputMode="numeric"
                />
              </Popover>
            </S.SystemWrapper>
          )}

          <Button type="submit" disabled={isSubmitButtonDisabled}>
            {t`actions.continue`}
          </Button>
        </form>
      </S.Column>
    </S.Wrapper>
  )
}
