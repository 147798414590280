import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import { StyledHeartCompanionVariant1 as S } from './HeartCompanionVariant1.styles'
import { USER_DATA_FEMALE, USER_DATA_MALE } from './constants'

export const HeartCompanionVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.heartCompanion.title`}</S.Title>
      </S.Column>

      <S.SwiperContainer>
        <Carousel
          slidesPerView="auto"
          spaceBetween={40}
          centeredSlides
          modules={[Pagination, Autoplay]}
          loop
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {(isMale ? USER_DATA_MALE : USER_DATA_FEMALE).map(
            ({ avatar, name, review }) => (
              <SwiperSlide key={name}>
                <S.AvatarContainer>
                  <img src={avatar} alt="user" />
                </S.AvatarContainer>

                <S.ReviewContainer>
                  <S.Author>{t(name)}</S.Author>
                  <S.Review>{t(review)}</S.Review>
                </S.ReviewContainer>
              </SwiperSlide>
            ),
          )}
        </Carousel>
      </S.SwiperContainer>
      <Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </Button>
    </S.Wrapper>
  )
}
