import React from 'react'

import {
  Answer,
  AnswerWithCheckbox,
  Button,
  answerTheme,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { StyledDynamicLeftToRightVariant2 as S } from './DynamicLeftToRightVariant2.styles'

export const DynamicLeftToRightVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <S.Column>
        {!!title && <S.Title marginBottom={subtitle ? 8 : 24}>{title}</S.Title>}
        {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

        {optionType === OptionType.RADIO && (
          <S.OptionsContainer>
            {optionData?.map(({ value, text, id }) => (
              <Answer
                {...optionProps}
                theme={answerTheme.CARDIMATE}
                key={id}
                value={value}
                disabled={isAnswersDisabled}
                margin="0 0 12px"
              >
                {!!text && text}
              </Answer>
            ))}
          </S.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <S.OptionsContainer>
            {optionData?.map(({ id, value, text }) => (
              <AnswerWithCheckbox
                {...optionProps}
                theme={answerWithCheckboxTheme.CARDIMATE}
                value={value}
                key={id}
                checked={answers.includes(value)}
                iconSrc={checkIcon}
                margin="0 0 12px"
              >
                {!!text && text}
              </AnswerWithCheckbox>
            ))}
          </S.OptionsContainer>
        )}

        {optionType === OptionType.CHECKBOX && (
          <StickyButtonContainer>
            <Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
              theme={buttonTheme.CARDIMATE}
            >
              {!!buttonText && buttonText}
            </Button>
          </StickyButtonContainer>
        )}
      </S.Column>
    </S.Wrapper>
  )
}
