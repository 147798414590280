import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants/common'

import { StyledPreparingPlan as S } from './PreparingPlan.styles'

export const PreparingPlanTextVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const hasAlternativeStyles =
    cohortToUse === Cohort.CARDIMATE_20 || cohortToUse === Cohort.CARDIMATE_21

  useEffect(() => {
    const timerId = setTimeout(() => {
      handleContinue('')
    }, 2000)

    return () => {
      clearTimeout(timerId)
    }
  }, [handleContinue])

  return (
    <S.Wrapper hasAlternativeStyles={hasAlternativeStyles}>
      <S.Text hasAlternativeStyles={hasAlternativeStyles}>
        {t`onboarding.preparingPlanText.title`}
      </S.Text>
    </S.Wrapper>
  )
}
