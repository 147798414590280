import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { ISvg } from 'components/SvgImage/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import oldLogo from 'assets/images/app-name-logo.png'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

type TProps = {
  logo?: string
  width?: number
  height?: number
  guaranteeImg?: ISvg
}

export const MoneyBackGuarantee: React.FC<TProps> = ({
  logo = oldLogo,
  width = 121,
  height = 28,
  guaranteeImg,
  ...props
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  return (
    <S.Wrapper {...props}>
      {guaranteeImg ? (
        <S.Image svg={guaranteeImg} />
      ) : (
        <img
          src={`${CDN_FOLDER_LINK}${Images.MONEY_BACK_GUARANTEE}_${language}.png`}
          alt="money_back_guarantee"
        />
      )}
      <S.Logo src={logo} alt="logo" width={width} height={height} />
      <S.LinksWrapper>
        <TermsOfUseLink />
        <PrivacyPolicyLink />
      </S.LinksWrapper>
      <p>{t`commonComponents.description`}</p>
    </S.Wrapper>
  )
}
