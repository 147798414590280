import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'

import { StyledEthnicity as S } from './Ethnicity.styles'

const PAGE_NAME = 'Ethnicity question'
export const OPTION_VALUES = {
  WHITE: 'white_people',
  BLACK_OR_AFRICAN_AMERICANS: 'black_or_african_americans',
  HISPANICS: 'hispanics',
  ASIAN_AMERICANS: 'asian_americans',
  AMERICAN_INDIANS_OR_ALASKA_NATIVES: 'american_indians_or_alaska_natives',
  HAWAIIANS_OR_OTHER_ISLANDERS: 'hawaiians_or_other_islanders',
  MULTIRACIALS: 'multiracials',
  PREFER_NOT_TO_SAY: 'prefer_not_to_say',
}

export const EthnicityVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.ethnicity.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.ethnicity.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.WHITE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.white`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BLACK_OR_AFRICAN_AMERICANS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.blackOrAfrican`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.HISPANICS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.hispanic`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ASIAN_AMERICANS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.asian`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.AMERICAN_INDIANS_OR_ALASKA_NATIVES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.americanIndian`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.HAWAIIANS_OR_OTHER_ISLANDERS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.hawaiian`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MULTIRACIALS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.ethnicity.multiracial`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PREFER_NOT_TO_SAY}
            disabled={isAnswersDisabled}
          >
            <S.Button>{t`onboarding.ethnicity.preferNotToSay`}</S.Button>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
