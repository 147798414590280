import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { paymentApi, userApi } from 'api'

import { sendUserConfigAction } from 'root-redux/actions/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useUserData } from 'hooks/user/useUserData'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { setPaymentMethodAction } from 'modules/payment/redux/actions/common'

import { Modal } from 'components/Modal'
import { PaymentSuccess } from 'components/PaymentSuccess'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

import { logFailedPayment } from '../../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import { StyledPayPalContainer as S } from './PayPalContainer.styles'

export const PayPalContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const {
    uuid,
    cohort,
    selectedSubscription,
    paypalPlanId,
    paypalClientId,
    currency,
    currentPrice,
    trialPrice,
    trialPriceId,
    trialPeriodDays,
    email,
    periodName,
    periodQuantity,
    screenName,
  } = usePurchaseStore()

  const { goal } = useUserData()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const handleResetError = () => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }

  const handlePaymentApprove = async (data) => {
    const predictedLtvResponse = await userApi.getPredictedLtv(uuid)

    logSuccessfulPayment({
      email,
      subscriptionId: data.subscriptionID,
      productId: paypalPlanId,
      uuid,
      price: currentPrice,
      trialPrice,
      predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
      trialPeriodDays,
      currency,
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      goal,
      screenName,
      isPersonalDataAllowed,
    })

    const response = await paymentApi.createPaypalSubscription({
      uuid,
      paypalPlanId,
      cohort,
    })

    if (response.status) {
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: PaymentMethod.PAYPAL,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: paypalPlanId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
        }),
      )

      setIsPaymentStatusShown(true)
      setIsErrorModalShown(false)
    }
  }

  const handlePaymentError = (error) => {
    setIsErrorModalShown(true)
    logFailedPayment({
      email,
      productId: paypalPlanId,
      priceDetails: {
        price: currentPrice,
        currency,
        trial: !!trialPriceId,
      },
      paymentResponse: {
        type: error?.name || '',
        code: error?.debug_id,
        message: error?.message,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      goal,
      screenName,
      isPersonalDataAllowed,
    })
  }

  const handleButtonClick = () => {
    dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))

    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })

    if (window.snaptr) {
      window.snaptr('track', 'ADD_CART', {
        user_email: isPersonalDataAllowed ? email : '',
      })
    }

    if (window.ttq) {
      window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
      window.ttq.track('AddToCart', { event_id: uuid })
    }

    eventLogger.logPaymentMethodSelected({
      paymentMethod: PaymentMethod.PAYPAL,
    })
    eventLogger.logPurchaseStarted({
      email,
      productId: paypalPlanId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPriceId,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      goal,
      screenName,
      isPersonalDataAllowed,
    })
  }

  return (
    <>
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: paypalPlanId,
                  custom_id: uuid,
                })
              }}
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && <PaymentSuccess />}
      <Modal onClose={handleResetError} isShown={isErrorModalShown}>
        {t`payment.error`}
      </Modal>
    </>
  )
}
