export const COMMON_OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
  DONT_KNOW: 'dont_know',
  OFTEN: 'quite_often',
  SOMETIMES: 'sometimes',
  RARELY: 'rarely',
  NEVER: 'never',
  MAYBE: 'maybe',
  OCCASIONALLY: 'occasionally',
  FREQUENTLY: 'frequently',
  ALWAYS: 'always',
  PREFER_NOT_TO_ANSWER: 'prefer_not_to_answer',
  NOT_SURE: 'not_sure',
}

export const HEALTH_CONDITION_OPTION_VALUES = {
  HIGH_BLOOD_PRESSURE: 'high_blood_pressure',
  CHOLESTEROL: 'high_cholesterol',
  HIGH_HEART_RATE: 'high_heart_rate',
  NONE: 'none_of_the_above',
}

export const SLEEP_OPTION_VALUES = {
  LESS_THAN_6: 'less_than_6_hours',
  AVERAGE: '6_8_hours',
  MORE_THAN_8: 'more_than_8_hours',
  VARIES: 'varies',
}

export const BLOOD_PRESSURE_OPTION_VALUES = {
  DONT_KNOW: 'dont_know',
  LESS_120_LESS_80: '<120 / <80',
  BETWEEN_120_129_AND_LESS_80: '120–129 / <80',
  BETWEEN_120_129_AND_80: '120–129/80',
  BETWEEN_130_139_AND_80_89: '130–139 / 80–89',
  MORE_140_MORE_90: '>140 / >90',
}

export const MAIN_GOAL_OPTION_VALUES = {
  CONTROL_HEART_HEALTH: 'heart_health',
  CONTROL_BLOOD_PRESSURE: 'blood_pressure',
  REDUCE_STRESS: 'reduce_stress',
  SLEEP_BETTER: 'sleep_better',
  REDUCE_MEDICATION_INTAKE: 'medication_intake',
  OTHER: 'other',
}
