import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getVariantAction } from 'root-redux/actions/common'

import { getIsMyCardimateDomain } from 'helpers/getIsMyCardimateDomain'

import { Cohort } from 'root-constants/common'

export const useSetVariant = (): void => {
  const isMyCardimateDomain = getIsMyCardimateDomain()

  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  const defaultCohort = isMyCardimateDomain
    ? Cohort.CARDIMATE_16_NP
    : Cohort.CARDIMATE_14

  useEffect(() => {
    dispatch(getVariantAction({ cohort: cohortFromUrl || defaultCohort }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
