import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import {
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  INTRO_OFFER_PERIODS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPriceInfoWithDynamicDiscount as S } from './PriceInfoWithDynamicDiscount.styles'

export const PriceInfoWithDynamicDiscount: React.FC = () => {
  const { t } = useTranslation()
  const {
    trialPrice,
    trialPeriodDays,
    dailyTrialPrice,
    currency,
    dynamicDiscount,
  } = usePurchaseStore()

  const pricePeriodContext =
    trialPeriodDays > TrialPeriod.ONE_WEEK
      ? TimeInterval.MONTH
      : TimeInterval.WEEK

  const oldPriceWithoutDiscount = getPriceBeforeDiscount(
    dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    trialPrice,
  )

  const priceWithDiscount = Number(oldPriceWithoutDiscount) - trialPrice

  return (
    <S.Wrapper>
      <S.MainPriceContainer>
        <S.MainPriceText>
          {t('payment.planDescription', {
            periodQuantity: INTRO_OFFER_PERIODS[trialPeriodDays],
            context: pricePeriodContext,
          })}
        </S.MainPriceText>
        <S.MainPriceValue>
          {t('payment.price', {
            value: oldPriceWithoutDiscount,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.MainPriceValue>
        <S.MainPriceText>
          {dynamicDiscount?.amount}% {dynamicDiscount?.checkout}
        </S.MainPriceText>
        <S.MainPriceValue isDiscount>
          -
          {t('payment.price', {
            value: priceWithDiscount,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.MainPriceValue>
      </S.MainPriceContainer>

      <S.TotalPerDayContainer>
        <S.TotalPerDayText>{t`payment.totalPerDay`}</S.TotalPerDayText>
        <S.TotalPerDayPrice>
          {t('payment.price', {
            value: dailyTrialPrice,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.TotalPerDayPrice>
      </S.TotalPerDayContainer>

      <S.TotalContainer>
        <S.TotalText>{t`payment.total`}</S.TotalText>
        <S.TotalPrice>
          <Trans
            i18nKey="payment.totalPerPeriod"
            values={{
              price: trialPrice,
              context: pricePeriodContext,
              periodQuantity: INTRO_OFFER_PERIODS[trialPeriodDays],
              currencyCode: currency.toUpperCase(),
              minimumFractionDigits: 2,
            }}
          />
        </S.TotalPrice>
        <S.TotalDiscount>
          {t('payment.saved', {
            discountAmount: priceWithDiscount,
            discountPercent: dynamicDiscount?.amount,
            currencyCode: currency.toUpperCase(),
            minimumFractionDigits: 2,
          })}
        </S.TotalDiscount>
      </S.TotalContainer>
    </S.Wrapper>
  )
}
