import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useURLTitle } from 'hooks/common/useURLTitle'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { MoneyBackGuarantee } from 'modules/subscriptions/components/common/MoneyBackGuarantee'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { Discount } from 'modules/subscriptions/components/discount'
import { PaidTrialItemVariant3 } from 'modules/subscriptions/components/trial/PaidTrialItemVariant3'
import {
  COMMON_BENEFITS,
  PLAN_ITEMS,
  TRIAL_PLAN_ITEMS,
} from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { HeaderWithButton } from 'components/HeaderWithButton'
import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import heartRateImg from 'assets/images/subscriptions-heart-rate.jpg'

import {
  DEFAULT_NONE_TRIAL_DISCOUNT_AMOUNT,
  DEFAULT_TRIAL_DISCOUNT_AMOUNT,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledSubscriptionsVariant6 as S } from './SubscriptionsVariant6.styles'

export const SubscriptionsVariant6: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)

  const { subscriptions, dynamicDiscount } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: SubscriptionTag.NO_TAX,
  })

  const { subscriptionBlockType } = useDynamicPaywallConfig()
  const urlTitle = useURLTitle()

  const isTrialPlan = TRIAL_PLAN_ITEMS.includes(subscriptionBlockType)

  const discountAmount = useMemo(() => {
    if (dynamicDiscount) {
      return dynamicDiscount.amount
    }
    if (isTrialPlan) {
      return DEFAULT_TRIAL_DISCOUNT_AMOUNT
    }
    return DEFAULT_NONE_TRIAL_DISCOUNT_AMOUNT
  }, [isTrialPlan, dynamicDiscount])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleScroll = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <HeaderWithButton onClick={handleScroll} />
      <DynamicDiscountBanner marginTop={48} />
      <S.Content paddingTop={dynamicDiscount ? 40 : 80}>
        <SubheaderWithTimer discount={discountAmount} />
        <S.Congratulations>{t`subscriptions.congrats`}</S.Congratulations>
        <S.Title>{urlTitle || t`subscriptions.rightTrack`}</S.Title>
        <S.Disclaimer>{t`subscriptions.trackingTools`}</S.Disclaimer>

        <S.Benefits>
          <ul>
            {COMMON_BENEFITS.map((path) => (
              <S.ListItem key={path}>{t(path)}</S.ListItem>
            ))}
          </ul>
          <S.ImageContainer>
            <img src={heartRateImg} alt="scores on mobile phone" />
          </S.ImageContainer>
        </S.Benefits>
        <Discount />
        <S.SelectPlanBlock
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={
            dynamicDiscount
              ? PaidTrialItemVariant3
              : PLAN_ITEMS[subscriptionBlockType]
          }
        />
        <S.Button onClick={handleShowPayment}>{t`actions.getCardi`}</S.Button>
        {isTrialPlan ? (
          <SubscriptionDescription />
        ) : (
          <S.CancelInfo>{t`subscriptions.disclaimer`}</S.CancelInfo>
        )}
        <ReviewsSlider
          titlePath="subscriptions.customersLove"
          titleBottomMargin={24}
          marginBottom={32}
        />
        <MoneyBackGuarantee />
      </S.Content>
    </S.Wrapper>
  )
}
