import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/user/useUserData'

import { ADDITIONAL_GOALS_TO_MARKUP } from 'root-constants/common'

export const useUserAdditionalGoals = (maxSize: number) => {
  const { t } = useTranslation()
  const { additionalGoals } = useUserData()

  return additionalGoals
    .slice(0, maxSize)
    .map((goal) => t(ADDITIONAL_GOALS_TO_MARKUP[goal]))
}
