import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledTotalAmountVAT as S } from './TotalAmountVAT.styles'

export const TotalAmountVAT: React.FC = () => {
  const { t } = useTranslation()
  const { currentPrice, periodName, periodQuantity, currency } =
    usePurchaseStore()

  return (
    <S.Container>
      <Trans
        i18nKey="payment.totalAmountVAT"
        values={{
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice.toFixed(2),
          periodQuantity,
          minimumFractionDigits: 2,
        }}
        components={{
          periodName: (
            <S.PeriodName>
              {t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
                count: periodQuantity,
              })}
            </S.PeriodName>
          ),
        }}
      />
    </S.Container>
  )
}
