import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CongratulationsHeartHealth } from '../CongratulationsHeartHealth'
import { Facts } from '../Facts'
import { HealthyLevelGraph } from '../HealthyLevelGraph'
import { HeartAge } from '../HeartAge'
import { HeartLevel } from '../HeartLevel'
import { HighRateGraph } from '../HighRateGraph'
import { ReduceHeartAge } from '../ReduceHeartAge'
import { StyledHeartHealth as S } from './HeartHealth.styles'

type TProps = {
  handleButtonClick: () => void
}

export const HeartHealth: React.FC<TProps> = ({ handleButtonClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t`summary.heartHealth.title`}</S.Title>
      <CongratulationsHeartHealth />
      <Facts />
      <S.Title>{t`summary.heartHealth.freshStart`}</S.Title>
      <HeartLevel percent={82} iconVariant={2}>
        {t`summary.heartHealth.users`}
      </HeartLevel>
      <HealthyLevelGraph marginBottom={12}>
        <Trans i18nKey="summary.heartHealth.bringDown" />
      </HealthyLevelGraph>
      <ReduceHeartAge />
      <S.Button onClick={handleButtonClick}>{t`actions.getMyPlan`}</S.Button>
      <S.Title>{t`summary.heartHealth.heartHealthSummary`}</S.Title>
      <HighRateGraph>
        <Trans i18nKey="summary.heartHealth.highRate" />
      </HighRateGraph>
      <HeartAge />
    </>
  )
}
