import styled from 'styled-components'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofDoctorVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: 100dvh;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    flex-grow: 1;

    p:last-of-type {
      flex-grow: 1;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Text: styled.p`
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
  Img: styled.img`
    aspect-ratio: 375/272;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 24px 0 40px;
  `,
}
