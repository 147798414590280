import { useState } from 'react'

import { TAnswer } from 'models/common.model'

const PAGE_TRANSITION_DELAY = 500

export const useDelayedContinue = (): {
  isAnswersDisabled: boolean
  handleChange: (
    value: TAnswer,
    handleContinue: (value: TAnswer) => void,
  ) => void
  setIsAnswersDisabled: (value: boolean) => void
} => {
  const [isAnswersDisabled, setIsAnswersDisabled] = useState<boolean>(false)

  const handleChange = (
    value: TAnswer,
    handleContinue: (value: TAnswer) => void,
  ) => {
    setTimeout(() => {
      handleContinue(value)
    }, PAGE_TRANSITION_DELAY)
  }

  return { isAnswersDisabled, handleChange, setIsAnswersDisabled }
}
