import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserTTCLID } from 'root-redux/actions/user'
import { selectLanguage, selectTiktokPixelId } from 'root-redux/selects/common'
import {
  selectEmail,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { Language } from 'root-constants/common'

export const useTiktokPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectEmail)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)

  const { isEUUser, isPersonalDataAllowed, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  const shouldTiktokPixelBePaused =
    isEUUser && !hasAdvertisingAndTargetingCookie && language === Language.EN

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) {
      return
    }

    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page({ event_id: uuid })

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid && uuid) dispatch(sendUserTTCLID(ttclid))
  }, [
    dispatch,
    email,
    isPersonalDataAllowed,
    shouldTiktokPixelBePaused,
    tiktokPixelId,
    userCountryCode,
    uuid,
  ])
}
