import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/user/useUserData'

import heartImage from 'assets/images/heart.svg'

import { StyledReduceHeartAge as S } from './ReduceHeartAge.styles'

export const ReduceHeartAge: React.FC = () => {
  const { t } = useTranslation()
  const { age } = useUserData()

  return (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="summary.reduceHeartAge.title" values={{ age }} />
      </S.Title>
      <S.Img src={heartImage} height={127} alt="heart" />
      <S.Text>{t`summary.reduceHeartAge.text`}</S.Text>
    </S.Wrapper>
  )
}
