import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { PRICE_BACKGROUND } from 'modules/subscriptions/components/trial/PaidTrialItemVariant1/constants'
import {
  ProductKey,
  TRIAL_PERIOD_NAME_TO_MARKUP_MAP,
} from 'modules/subscriptions/constants'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { PriceValue } from 'components/PriceValue'

import priceBgImg from 'assets/images/price-bg-blue.svg'

import {
  CURRENCY_SYMBOLS,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  INTRO_OFFER_PERIODS,
} from 'root-constants/common'

import { StyledPlanItem as S } from './PaidTrialItemVariant3.styles'

export const PaidTrialItemVariant3: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    product,
    currency,
    trialPrices: { fullPrice: trialPrice, durationDays, daily },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const { cohortToUse, dynamicDiscount } = usePurchaseStore()

  const oldPrice = getPriceBeforeDiscount(
    dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    trialPrice,
  )

  const isSoldOut = product === ProductKey.ONE_WEEK_SOULDOUT

  return (
    <S.PlanItem
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-is-soldout={isSoldOut}
      data-text={
        isSoldOut ? t`subscriptions.soldOut` : t`subscriptions.mostPopular`
      }
    >
      <S.Container>
        <S.PlanTitle>
          {t('subscriptions.trialPlanPeriod', {
            periodName: t(TRIAL_PERIOD_NAME_TO_MARKUP_MAP[durationDays], {
              count: INTRO_OFFER_PERIODS[durationDays],
            }),
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
          })}
        </S.PlanTitle>
        <S.PricesWrapper>
          <S.OldPrice data-currency={currency}>
            <PriceValue value={oldPrice} currency={currency} />
          </S.OldPrice>
          <S.NewPrice data-currency={currency}>
            <PriceValue value={trialPrice} currency={currency} />
          </S.NewPrice>
        </S.PricesWrapper>
      </S.Container>
      {!!daily && (
        <S.Container>
          <S.CustomPrice
            data-is-selected={isSelected}
            data-currency-symbol-length={CURRENCY_SYMBOLS[currency]?.length}
            priceBackground={PRICE_BACKGROUND[cohortToUse] || priceBgImg}
          >
            <S.Price data-is-selected={isSelected} data-currency={currency}>
              <PriceValue value={daily} currency={currency} />
            </S.Price>
            <S.Period data-is-selected={isSelected}>
              {t`subscriptions.perDay`}
            </S.Period>
          </S.CustomPrice>
        </S.Container>
      )}
    </S.PlanItem>
  )
}
