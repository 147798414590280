import React from 'react'

import { Button, buttonTheme } from 'storybook-ui'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { StyledDynamicSocialProof as S } from './DynamicSocialProof.styles'

export const DynamicSocialProof: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    imageUrls: [firstImage],
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      {!!firstImage && <S.Img src={firstImage} alt="image" />}

      <S.Column>
        {!!title && <S.Title>{title}</S.Title>}
        {!!subtitle && <S.Text>{subtitle}</S.Text>}
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {!!buttonText && buttonText}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
