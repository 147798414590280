import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'

import { useAuthObserver } from 'hooks/services/useAuthObserver'
import { useGetRedirectResult } from 'hooks/services/useGetRedirectResult'
import { useInitFirebase } from 'hooks/services/useInitFirebase'

import { eventLogger } from 'services/eventLogger.service'

import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { StyledAccount as S } from './Account.styles'
import { EmailAccount } from './components/EmailAccount'

export const Account: React.FC = () => {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      <EmailAccount />

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
      >
        {error}
      </Modal>
    </S.Wrapper>
  )
}
