import { Goal } from 'root-constants/common'

export const GOALS = {
  [Goal.BLOOD_PRESSURE]: 'upsell.bloodPressure',
  [Goal.LOWER_CHOLESTEROL]: 'upsell.cholesterol',
  [Goal.HEART_RATE]: 'upsell.heartRate',
  [Goal.HEART_HEALTH]: 'upsell.heartRate',
  [Goal.HEART_ATTACK_PREVENTION]: 'upsell.heartRate',
  [Goal.REDUCE_STRESS]: 'upsell.heartHealth',
}
