import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useUserData } from 'hooks/user/useUserData'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { makeUpsellAction } from 'modules/payment/redux/actions/upsell'
import { UpsellBenefits } from 'modules/subscriptions/components/upsell/UpsellBenefits'
import { UpsellBenefitsDescription } from 'modules/subscriptions/components/upsell/UpsellBenefitsDescription'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { GlobalStatusBar } from 'components/GlobalStatusBar'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { goTo } from 'browser-history'
import {
  CURRENCY_SYMBOLS,
  Goal,
  SubscriptionListType,
  SubscriptionTag,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledUpsellVariant1 as S } from './UpsellVariant1.styles'
import { GOALS } from './constants'

export const UpsellVariant1: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const userTrialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  const hasIncludedVAT = useVatInfo()
  const { currentPrice, screenName, currency } = usePurchaseStore()
  const { goal, userGoals } = useUserData()

  const userGoal = useMemo(() => {
    if (Array.isArray(userGoals)) {
      return userGoals[0]
    }
    return userGoals
  }, [userGoals])

  const { hasPrices } = usePaywall('')

  useGetPrices({
    screenName: ScreenName.UPSELL_OFFER,
    subscriptionType: SubscriptionListType.UPSELL,
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown()
  }, [])

  const makeUpsell = () => {
    dispatch(makeUpsellAction(goal, screenName))
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose()
    goTo({ pathname: PageId.ACCOUNT, search })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.StatusContainer>
        <GlobalStatusBar completedStageNumber={2} />
      </S.StatusContainer>
      <S.Column>
        <S.Title>
          <Trans
            i18nKey="upsell.title"
            values={{ goal: t(GOALS[userGoal || Goal.HEART_HEALTH]) }}
          />
        </S.Title>
        <UpsellBenefits />

        <S.Button type="button" onClick={makeUpsell}>
          {t`actions.continue`}
        </S.Button>
        <S.LinkButton type="button" onClick={handleSkip}>
          {t`upsell.skipOffer`}
        </S.LinkButton>
        <S.Disclaimer>
          <Trans
            i18nKey="upsell.disclaimer"
            values={{
              price: currentPrice,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              currencyCode: currency.toUpperCase(),
              minimumFractionDigits: 2,
              context:
                userTrialPeriod && userTrialPeriod <= TrialPeriod.ONE_WEEK
                  ? TimeInterval.DAY
                  : TimeInterval.MONTH,
              vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
            }}
            components={{
              termsOfUse: <TermsOfUseLink />,
              supportLink: <SupportLink />,
            }}
          />
        </S.Disclaimer>

        <UpsellBenefitsDescription />
        <S.Button type="button" onClick={makeUpsell}>
          {t`actions.continue`}
        </S.Button>
        <S.LinkButton type="button" onClick={handleSkip}>
          {t`upsell.skipOffer`}
        </S.LinkButton>
      </S.Column>
      <StripePaymentProcessing />
    </S.Wrapper>
  )
}
