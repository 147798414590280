import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  ANIMATION_HIGH_RISK,
  ANIMATION_MEDIUM_RISK,
  AVERAGENESS_TO_MARKUP,
} from 'pages/cardiac-risk/constants'

import { OptionType } from 'components/Option'

import animationHighRisk from 'assets/animations/cardiac-risk-high.json'
import animationMediumRisk from 'assets/animations/cardiac-risk-medium.json'

import { Language } from 'root-constants/common'
import { COMMON_OPTION_VALUES } from 'root-constants/options'

import { StyledCardiacRiskVariant2 as S } from './CardiacRiskVariant2.styles'

export const CardiacRiskVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationHighRef = useRef<HTMLDivElement>(null)
  const animationMediumRef = useRef<HTMLDivElement>(null)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isButtonShowed, setIsButtonShowed] = useState(false)
  const [healthCheckup, setHealthCheckup] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.cardiacRisk.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setHealthCheckup(value)
      setIsModalShown(false)
      setIsButtonShowed(true)
    },
  }

  useEffect(() => {
    if (animationHighRef.current) {
      const animation = lottie.loadAnimation({
        name: ANIMATION_HIGH_RISK,
        container: animationHighRef.current,
        animationData: animationHighRisk,
        loop: false,
      })
      animation.addEventListener('complete', () => {
        setIsModalShown(true)
      })
    }
    return () => {
      lottie.destroy(ANIMATION_HIGH_RISK)
    }
  }, [])

  useEffect(() => {
    if (
      animationMediumRef.current &&
      healthCheckup === COMMON_OPTION_VALUES.YES
    ) {
      lottie.loadAnimation({
        name: ANIMATION_MEDIUM_RISK,
        container: animationMediumRef.current,
        animationData: animationMediumRisk,
        loop: false,
      })
    }
    return () => {
      lottie.destroy(ANIMATION_MEDIUM_RISK)
    }
  }, [healthCheckup])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Content>
          <S.Title>{t`onboarding.cardiacRisk.title`}</S.Title>
          <S.AnimationWrapper>
            <S.AnimationTitle>{t`onboarding.cardiacRisk.heartAttack2`}</S.AnimationTitle>
            {healthCheckup === COMMON_OPTION_VALUES.YES ? (
              <div ref={animationMediumRef} key={ANIMATION_MEDIUM_RISK} />
            ) : (
              <div ref={animationHighRef} key={ANIMATION_HIGH_RISK} />
            )}

            <S.VariantsWrapper>
              {AVERAGENESS_TO_MARKUP.map(({ text, color }) => (
                <S.Text key={text} color={color}>
                  {t(text)}
                </S.Text>
              ))}
            </S.VariantsWrapper>
          </S.AnimationWrapper>
        </S.Content>

        {isButtonShowed && (
          <Button
            onClick={() => handleContinue(healthCheckup)}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        )}

        {isModalShown && (
          <S.Modal>
            <S.Container>
              <S.ContainerTitle>
                <Trans i18nKey="onboarding.cardiacRisk.checkUp2" />
              </S.ContainerTitle>
              <S.ButtonWrapper>
                <S.Option {...optionProps} value={COMMON_OPTION_VALUES.YES}>
                  <S.YesButton>{t`onboarding.cardiacRisk.yes`}</S.YesButton>
                </S.Option>
                <S.Option {...optionProps} value={COMMON_OPTION_VALUES.NO}>
                  <S.NoButton>{t`onboarding.cardiacRisk.no`}</S.NoButton>
                </S.Option>
              </S.ButtonWrapper>
            </S.Container>
          </S.Modal>
        )}
      </S.Column>
    </S.Wrapper>
  )
}
