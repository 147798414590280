import React from 'react'
import { useDispatch } from 'react-redux'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { stopFetching } from 'root-redux/actions/common'
import { updateUserConfigAction } from 'root-redux/actions/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { usePrimerAnalytics } from 'modules/payment/hooks/usePrimerAnalytics'
import { SET_PRIMER_PAYMENT_FORM_IS_LOADING } from 'modules/payment/redux/actions/primer'

import { PaymentMethod, PaymentSystem } from 'root-constants/common'

import { PRIMER_PAY_PAL_ID } from '../constants'
import { setPaymentMethodAction } from '../redux/actions/common'

export const useInitPrimerPayPal = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const dispatch = useDispatch()
  const { uuid, selectedSubscription, email } = usePurchaseStore()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const { logPaypalPaymentStarted } = usePrimerAnalytics()

  const initPayPalButton = async () => {
    const paymentMethodManager =
      await primerRef.current?.createPaymentMethodManager(
        PaymentMethodType.PAYPAL,
      )

    if (!paymentMethodManager) return

    const payPalButton = paymentMethodManager.createButton()

    await payPalButton.render(PRIMER_PAY_PAL_ID, {
      style: {
        buttonType: 'short',
        buttonColor: 'gold',
        buttonHeight: 55,
      },
    })
    dispatch(stopFetching(SET_PRIMER_PAYMENT_FORM_IS_LOADING))

    payPalButton.addEventListener(EventTypes.CLICK, () => {
      eventLogger.logPaymentMethodSelected({
        paymentMethod: PaymentMethod.PAYPAL,
      })
      window.fbq('track', 'AddToCart', {}, { eventID: uuid })

      if (window.snaptr) {
        window.snaptr('track', 'ADD_CART', {
          user_email: isPersonalDataAllowed ? email : '',
        })
      }

      if (window.ttq) {
        window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
        window.ttq.track('AddToCart', { event_id: uuid })
      }
      googleAnalyticsLogger.logAddingToCart(
        selectedSubscription as ISubscription,
      )
      logPaypalPaymentStarted()

      dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))
      dispatch(updateUserConfigAction({ paymentSystem: PaymentSystem.PRIMER }))
    })
  }

  return {
    initPayPalButton,
  }
}
