import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { SvgImage } from 'components/SvgImage'

import heartRate from 'assets/images/sprite/heart-rate-2.svg'
import heart from 'assets/images/summary-heart-score.png'

import { goTo } from 'browser-history'
import { Gender } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSummaryHeartScore as S } from './SummaryHeartScore.styles'

export const SummaryHeartScore: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { age, userGender } = useUserData()

  useEffect(() => {
    eventLogger.logPresalePageShown({
      pageName: pageId,
    })
  }, [pageId])

  const handleContinue = () => {
    eventLogger.logPresalePageCompleted({
      pageName: pageId,
    })
    goTo({ pathname: PageId.SUMMARY_HEALTH_METRICS, search })
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="summary.summaryHeartScore.title" />
        </S.Title>

        <S.Card>
          <S.CardTitle>
            <Trans i18nKey="summary.summaryHeartScore.heartScore" />
          </S.CardTitle>
          <S.CardSubtitle>{t`summary.summaryHeartScore.theHigherTheBetter`}</S.CardSubtitle>
          <S.ImageContainer>
            <img src={heart} alt="heart" />
          </S.ImageContainer>
          <S.CardText>
            <Trans i18nKey="summary.summaryHeartScore.increaseScore" />
          </S.CardText>
        </S.Card>

        <S.Disclaimer>
          <SvgImage svg={heartRate} width={42} />
          <p>
            <Trans
              i18nKey="summary.summaryHeartScore.disclaimer"
              context={userGender || Gender.FEMALE}
              values={{ age: age || 40 }}
            />
          </p>
        </S.Disclaimer>

        <StickyButtonContainer>
          <Button type="button" onClick={handleContinue}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}
