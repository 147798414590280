import { useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  selectFacebookPixelIds,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { FBConsent, Language } from 'root-constants/common'

export const useFacebookPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const facebookPixelIds = useSelector(selectFacebookPixelIds)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const { isEUUser, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelIds.length || !uuid || !userCountryCode) {
      return
    }

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, { external_id: uuid })
    })
    window.fbq('track', 'PageView')
  }, [facebookPixelIds, userCountryCode, uuid])

  useEffect(() => {
    if (!window.fbq || !isEUUser || language !== Language.EN) return

    window.fbq(
      'consent',
      hasAdvertisingAndTargetingCookie ? FBConsent.GRANT : FBConsent.REVOKE,
    )
  }, [hasAdvertisingAndTargetingCookie, isEUUser, language])
}
