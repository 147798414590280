import { BLOOD_PRESSURE_OPTION_VALUES } from 'root-constants/options'

export const PAGE_NAME = 'Blood pressure question'

export const TITLE_PATH = 'onboarding.bloodPressure.averagePressure'

export const KIT_BLOOD_PRESSURE_OPTION_DATA = [
  {
    value: BLOOD_PRESSURE_OPTION_VALUES.LESS_120_LESS_80,
    text: 'onboarding.bloodPressure.below',
  },
  {
    value: BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80,
    text: BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_120_129_AND_80,
  },
  {
    value: BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89,
    text: BLOOD_PRESSURE_OPTION_VALUES.BETWEEN_130_139_AND_80_89,
  },
  {
    value: BLOOD_PRESSURE_OPTION_VALUES.MORE_140_MORE_90,
    text: 'onboarding.bloodPressure.above',
  },
  {
    value: BLOOD_PRESSURE_OPTION_VALUES.DONT_KNOW,
    text: 'actions.doNotKnow',
  },
]
