import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSocialProofVariant3 as S } from 'pages/social-proof/SocialProofVariant3.styles'
import { SOCIAL_PROOF_DATA } from 'pages/social-proof/constants'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

export const SocialProofVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Img src={SOCIAL_PROOF_DATA[pageId]?.image} alt="" />
      <S.Column>
        <S.Title>
          <Trans i18nKey={SOCIAL_PROOF_DATA[pageId]?.title} />
        </S.Title>
        <S.Text>
          <Trans i18nKey={SOCIAL_PROOF_DATA[pageId]?.text} />
        </S.Text>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
