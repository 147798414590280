import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import {
  CurrentEnvironment,
  HotJarClientId,
  Language,
} from 'root-constants/common'

import { useCookieConsentAnswer } from '../common/useCookieConsentAnswer'

export const useHotJarInitialization = (): void => {
  const isProduction = getCurrentEnv() === CurrentEnvironment.PROD
  const HOTJAR_CLIENT_ID = isProduction
    ? HotJarClientId.PROD
    : HotJarClientId.DEV
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const { isEUUser, hasAnalyticsAndPerformanceCookie } =
    useCookieConsentAnswer()

  const shouldHotJarBePaused =
    isEUUser && !hasAnalyticsAndPerformanceCookie && language === Language.EN

  useLayoutEffect(() => {
    if (shouldHotJarBePaused || !userCountryCode) return

    const script = document.createElement('script')
    script.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_CLIENT_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    script.async = true
    document.head.appendChild(script)
  }, [HOTJAR_CLIENT_ID, shouldHotJarBePaused, userCountryCode])
}
