import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectIsInContextShown } from 'root-redux/selects/common'

import { getEnvironment } from 'helpers/getEnvironment'

export const useInContextInitialization = () => {
  const isInContextShown = useSelector(selectIsInContextShown)
  const { isStageEnvironment } = getEnvironment()

  useLayoutEffect(() => {
    if (!isStageEnvironment || !isInContextShown) return

    const initialScript = document.createElement('script')
    const externalScript = document.createElement('script')

    initialScript.type = 'text/javascript'
    initialScript.text = `
      var _jipt = [];
      _jipt.push(['project', 'ed9930b26d6030f291da8394973d4351']);
      _jipt.push(['domain', 'gismart']);
    `

    externalScript.type = 'text/javascript'
    externalScript.src = '//cdn.crowdin.com/jipt/jipt.js'
    externalScript.async = true

    document.body.appendChild(initialScript)
    document.body.appendChild(externalScript)
  }, [isStageEnvironment, isInContextShown])
}
