import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'
import { useUserSymptoms } from 'hooks/user/useUserSymptoms'

import { TPageProps } from 'models/common.model'

import { MAX_SYMPTOMS_SIZE } from 'pages/blood-pressure-graph/constants'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import graph from 'assets/images/blood-pressure-graph.png'

import { Gender } from 'root-constants/common'

import { StyledBloodPressureGraphVariant3 as S } from './BloodPressureGraphVariant3.styles'

export const BloodPressureGraphVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { age, userGender } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const userSymptoms = useUserSymptoms(MAX_SYMPTOMS_SIZE)

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t('onboarding.bloodPressureGraph.title2', {
            context: userGender || Gender.FEMALE,
            age,
          })}
        </S.Title>
        <S.GraphWrapper>
          <S.BenefitsList>
            <li>
              <Trans i18nKey="onboarding.bloodPressureGraph.trackingSystem" />
            </li>
            <S.ImageContainer>
              <img src={graph} alt="graph" />
            </S.ImageContainer>
            <li>
              <Trans i18nKey="onboarding.bloodPressureGraph.healthyPressure" />
            </li>
            <li>
              <Trans
                i18nKey="onboarding.bloodPressureGraph.minimize"
                values={{
                  userSymptoms: userSymptoms.length
                    ? userSymptoms
                    : t('onboarding.bloodPressureGraph.irregularities'),
                }}
              />
            </li>
          </S.BenefitsList>
        </S.GraphWrapper>
        <StickyButtonContainer>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
