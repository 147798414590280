import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { TRIAL_COHORTS_WITH_TOTAL_AMOUNT } from 'modules/payment/constants'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialAmount as S } from './TrialAmount.styles'

export const TrialAmount: React.FC = () => {
  const { t } = useTranslation()

  const { cohortToUse, trialPrice, trialPeriodDays, currency } =
    usePurchaseStore()

  const hasTotalAmount = TRIAL_COHORTS_WITH_TOTAL_AMOUNT.includes(cohortToUse)

  const hasFreeGuides = useFeatureIsOn('web_car970_free_guidesv2')

  return hasTotalAmount ? (
    <S.IntroOfferContainer>
      <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>

      {hasFreeGuides && (
        <S.ExtraBonus>
          <Trans i18nKey="payment.extraBonus" />
        </S.ExtraBonus>
      )}

      <S.Description>
        {t('payment.trialTotalAmount.total', {
          currencySymbol: CURRENCY_SYMBOLS[currency],
          trialPrice,
          context: TRIAL_DESCRIPTION_CONTEXT[trialPeriodDays],
          minimumFractionDigits: 2,
        })}
      </S.Description>
    </S.IntroOfferContainer>
  ) : (
    <S.Container>
      <span>{t('payment.trialTotalAmount.period', { trialPeriodDays })}</span>{' '}
      <strong>
        {CURRENCY_SYMBOLS[currency]}
        {trialPrice}
      </strong>
    </S.Container>
  )
}
