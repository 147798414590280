import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { setLanguageAction } from 'root-redux/actions/common'
import {
  selectIsInContextShown,
  selectLanguage,
} from 'root-redux/selects/common'

import {
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants/common'

import { StyledLanguageSelector as S } from './LanguageSelector.styles'

export const LanguageSelector = () => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)
  const language = useSelector(selectLanguage)
  const locales = isInContextShown
    ? [...SUPPORTED_LOCALES, Language.IN_CONTEXT]
    : SUPPORTED_LOCALES

  const options = locales.map((lang) => ({
    value: lang,
    label: lang.toUpperCase(),
  }))

  const initValue = options.find((option) => option.value === language)

  const onChange = (option) => {
    if (!option) return
    const url = new URL(window.location.href)
    url.searchParams.set(LANG_QUERY_PARAM, option.value)
    window.history.pushState({}, '', url)
    dispatch(setLanguageAction(option.value))
  }

  return (
    <S.Wrapper>
      <Select
        defaultValue={initValue}
        name="language"
        options={options}
        onChange={onChange}
        classNamePrefix="language-select"
        isSearchable={false}
      />
    </S.Wrapper>
  )
}
