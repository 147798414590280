import React from 'react'

import { useDynamicPage } from 'hooks/common/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { StyledDynamicStatement as S } from './DynamicStatement.styles'
import { OPTIONS_NUMBER } from './constants'

export const DynamicStatement: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    pageTexts: [firstText, secondText],
    optionProps,
    isAnswersDisabled,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <S.Column>
        <S.TextContainer>
          {!!title && <S.Title>{title}</S.Title>}
          {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </S.TextContainer>
        <S.OptionsContainer>
          {Array.from({ length: OPTIONS_NUMBER })
            .map((_, i) => i + 1)
            .map((number) => (
              <S.Option
                {...optionProps}
                value={number.toString()}
                disabled={isAnswersDisabled}
                key={number}
              >
                <S.QuestionButton>{number}</S.QuestionButton>
              </S.Option>
            ))}
        </S.OptionsContainer>
        <S.Description>
          <span>{firstText}</span>
          <span>{secondText}</span>
        </S.Description>
      </S.Column>
    </S.Wrapper>
  )
}
