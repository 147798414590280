import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'
import { COMMON_OPTION_VALUES } from 'root-constants/options'

import { StyledPainDuringActivityVariant2 as S } from './PainDuringActivityVariant2.styles'

export const PainDuringActivityVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.painDuringActivity2.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.painDuringActivity2.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.NEVER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.never`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.OCCASIONALLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.occasionally`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.FREQUENTLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.frequently`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={COMMON_OPTION_VALUES.ALWAYS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`actions.always`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
