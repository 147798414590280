import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithIconAndCheckbox,
  Button,
  answerWithIconAndCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { CheckboxCustomValue, Language } from 'root-constants/common'

import { StyledFoodsVariant1 as S } from './FoodsVariant1.styles'
import { FOODS_OPTION_DATA } from './constants'

export const FoodsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.foods.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === CheckboxCustomValue.NONE_OF_THE_ABOVE) {
        setAnswers([CheckboxCustomValue.NONE_OF_THE_ABOVE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== CheckboxCustomValue.NONE_OF_THE_ABOVE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.foods.title`}</S.Title>
        <S.Subtitle>{t`onboarding.foods.subtitle`}</S.Subtitle>

        <StyledOption.OptionsContainer>
          {FOODS_OPTION_DATA.map(({ value, text, image }) => (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              theme={answerWithIconAndCheckboxTheme.CARDIMATE}
              value={value}
              key={value}
              checked={answers.includes(value)}
              iconSrc={checkIcon}
              imageSrc={image}
              margin="0 0 12px"
            >
              {t(text)}
            </AnswerWithIconAndCheckbox>
          ))}
        </StyledOption.OptionsContainer>

        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
