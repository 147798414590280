import React, { ReactNode } from 'react'

import i18n from 'i18next'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { eventLogger } from 'services/eventLogger.service'

import { CurrentEnvironment } from 'root-constants/common'

type TProps = {
  children?: ReactNode
  className?: string
}

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-cardimate.gidev.xyz/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.cardimate.com/contact-form',
  [CurrentEnvironment.STAGE]:
    'https://account-cardimate.gistage.com/contact-form',
}

export const SupportLink: React.FC<TProps> = ({
  children = i18n.t('commonComponents.contactSupport'),
  className,
}) => {
  const currentEnv = getCurrentEnv()

  const link = CONTACT_FORM_LINKS[currentEnv || CurrentEnvironment.STAGE]

  const handleClick = () => eventLogger.logNeedHelpClicked()

  return (
    <a
      href={link}
      className={className}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
