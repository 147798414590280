import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { DYNAMIC_DISCOUNT_THEME } from 'root-constants/common'

import { StyledDiscount as S } from './Discount.styles'
import {
  DYNAMIC_DISCOUNT_CONTAINER_THEME,
  DYNAMIC_DISCOUNT_TITLE_COLORS,
} from './constants'

type TProps = {
  marginBottom?: number
}
export const Discount: React.FC<TProps> = ({ marginBottom = 25 }) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return dynamicDiscount ? (
    <S.Wrapper
      backgroundColor={
        DYNAMIC_DISCOUNT_CONTAINER_THEME[dynamicDiscount?.theme] ||
        DYNAMIC_DISCOUNT_CONTAINER_THEME.orange
      }
      marginBottom={marginBottom}
    >
      <S.Discount
        backgroundColor={
          DYNAMIC_DISCOUNT_THEME[dynamicDiscount?.theme] ||
          DYNAMIC_DISCOUNT_THEME.orange
        }
      >
        {t('subscriptions.discountBlock.discount', {
          discount: dynamicDiscount?.amount,
        })}
      </S.Discount>
      <S.Title
        color={
          DYNAMIC_DISCOUNT_TITLE_COLORS[dynamicDiscount?.theme] ||
          DYNAMIC_DISCOUNT_TITLE_COLORS.orange
        }
      >
        {t`subscriptions.discountBlock.discountLabel`}
      </S.Title>
    </S.Wrapper>
  ) : null
}
