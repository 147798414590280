import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'
import { HEALTH_CONDITION_OPTION_VALUES } from 'root-constants/options'

import { StyledHealthCondition as S } from './HealthCondition.styles'

const PAGE_NAME = 'Health condition question'

export const HealthConditionVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.healthCondition.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === HEALTH_CONDITION_OPTION_VALUES.NONE) {
        setAnswers([HEALTH_CONDITION_OPTION_VALUES.NONE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== HEALTH_CONDITION_OPTION_VALUES.NONE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.healthCondition.title`}</S.Title>
        <S.Text>{t`commonComponents.selectMoreThanOne`}</S.Text>
        <StyledOption.OptionsContainer marginBottom={13}>
          <Option
            {...optionProps}
            value={HEALTH_CONDITION_OPTION_VALUES.HIGH_BLOOD_PRESSURE}
            checked={answers.includes(
              HEALTH_CONDITION_OPTION_VALUES.HIGH_BLOOD_PRESSURE,
            )}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.healthCondition.answers.highBloodPressure`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={HEALTH_CONDITION_OPTION_VALUES.HIGH_HEART_RATE}
            checked={answers.includes(
              HEALTH_CONDITION_OPTION_VALUES.HIGH_HEART_RATE,
            )}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.healthCondition.answers.highHeartRate`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={HEALTH_CONDITION_OPTION_VALUES.NONE}
            checked={answers.includes(HEALTH_CONDITION_OPTION_VALUES.NONE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`actions.none`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
