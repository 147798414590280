import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'
import { usePageInfo } from 'providers/PageInfoProvider'

import { resetErrorAction } from 'root-redux/actions/common'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useUserData } from 'hooks/user/useUserData'

import { eventLogger } from 'services/eventLogger.service'

import {
  CENTS_IN_DOLLAR,
  PERIOD_NAME_TO_MARKUP_MAP,
  PaymentMethod,
  TrialPeriod,
} from 'root-constants/common'

import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
  setIsPaymentPopupActiveAction,
  setPaymentMethodAction,
  setPaymentRequestButtonTypeAction,
} from '../../redux/actions/common'
import { Divider } from '../Divider'
import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

type TProps = {
  isDividerVisible?: boolean
}

export const PaymentRequestButton: React.FC<TProps> = ({
  isDividerVisible = false,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonType, setButtonType] = useState<PaymentMethod | null>(null)

  const {
    trialPrice,
    trialPeriodDays,
    periodQuantity,
    periodName,
    currentPrice,
    currency,
  } = usePurchaseStore()

  const { currentPageId } = usePageInfo()
  const { goal } = useUserData()

  const calculatedPrice = +(
    (trialPrice || currentPrice) * CENTS_IN_DOLLAR
  ).toFixed()

  const planPeriodDescription =
    !!trialPeriodDays && trialPeriodDays <= TrialPeriod.ONE_WEEK
      ? t('payment.appleAndGooglePayPopupTrialText', { trialPeriodDays })
      : t('payment.appleAndGooglePayPopupText', {
          periodQuantity,
          periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
            count: periodQuantity,
          }),
        })

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        const shownButtonType = result?.applePay
          ? PaymentMethod.APPLE_PAY
          : PaymentMethod.GOOGLE_PAY

        setPaymentRequest(pr)
        setButtonType(shownButtonType)
        dispatch(setPaymentRequestButtonTypeAction(shownButtonType))
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
          goal,
        }),
      )
    })
    pr.on('cancel', () => dispatch(setIsPaymentPopupActiveAction(false)))
  }, [
    calculatedPrice,
    currency,
    currentPageId,
    dispatch,
    elements,
    goal,
    planPeriodDescription,
    stripe,
  ])

  const handleClick = () => {
    if (!buttonType) return

    dispatch(setIsPaymentPopupActiveAction(true))
    dispatch(setPaymentMethodAction(buttonType))
    eventLogger.logPaymentMethodSelected({ paymentMethod: buttonType })
  }

  return paymentRequest ? (
    <>
      {isDividerVisible && <Divider />}
      <S.Wrapper {...props}>
        <PaymentRequestButtonElement
          onClick={handleClick}
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                height: '56px',
              },
            },
          }}
        />
      </S.Wrapper>
    </>
  ) : null
}
