import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction, setProductName } from 'root-redux/actions/user'

import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useUserData } from 'hooks/user/useUserData'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  Description,
  TrialDescription,
} from 'modules/payment/components/Description'
import { PrimerPaymentForm } from 'modules/payment/components/PrimerPaymentForm'
import { TotalAmount } from 'modules/payment/components/TotalAmount'
import { TotalAmountVAT } from 'modules/payment/components/TotalAmountVAT'
import { TrialAmountWithDynamicDiscount } from 'modules/payment/components/TrialAmountWithDynamicDiscount'
import { COUNTRIES_WITHOUT_PAYPAL } from 'modules/payment/constants'

import paymentServicesImage from 'assets/images/payment-services.png'

import { goBack, replaceHistory } from 'browser-history'
import { Cohort } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { Divider } from '../Divider'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import { TrialAmount } from '../TrialAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const MixedPaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  const {
    cohort,
    cohortToUse,
    screenName,
    trialPriceId,
    hasCancelOffer,
    productId: productName,
    dynamicDiscount,
    countryCode,
  } = usePurchaseStore()

  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()
  const { goal } = useUserData()

  const isFifteenCohort = cohortToUse === Cohort.CARDIMATE_15

  const hasPaypal =
    !COUNTRIES_WITHOUT_PAYPAL.includes(countryCode.toLowerCase()) &&
    !isFifteenCohort

  const paymentAmount = useMemo(() => {
    if (dynamicDiscount) {
      return <TrialAmountWithDynamicDiscount />
    }
    if (trialPriceId) {
      return <TrialAmount />
    }
    if (!trialPriceId && hasIncludedVAT) {
      return <TotalAmountVAT />
    }
    return <TotalAmount />
  }, [trialPriceId, hasIncludedVAT, dynamicDiscount])

  const handleBack = () => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      goal,
      screenName,
    })

    if (hasCancelOffer && screenName !== ScreenName.CANCEL_OFFER) {
      dispatch(setSubscriptionListAction([]))
      dispatch(setProductName(productName))
      dispatch(
        sendUserConfigAction({
          product_name: productName,
        }),
      )
      replaceHistory({ pathname: PageId.CANCEL_OFFER_DISCOUNT_1, search })
      return
    }
    goBack()
  }

  return (
    <>
      {!trialPriceId && <S.Title>{t`payment.paymentFlow.getPlan`}</S.Title>}
      {paymentAmount}
      <S.Form marginBottom={isFifteenCohort ? 24 : 0}>
        <StripePaymentForm />
      </S.Form>
      {hasPaypal && (
        <>
          <Divider />
          <PrimerPaymentForm />
        </>
      )}
      <PaymentRequestButton isDividerVisible={!hasPaypal} />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
        hasMarginTop={!hasPaypal}
      />
      <S.Guarantee>{t`payment.paymentFlow.moneyBack`}</S.Guarantee>
      <S.BackButton onClick={handleBack}>
        {t`payment.paymentFlow.backToPlans`}
      </S.BackButton>
      {trialPriceId ? <TrialDescription /> : <Description />}
    </>
  )
}
