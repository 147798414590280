import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { createProductId } from 'helpers/createProductId'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { ProductKey } from 'modules/subscriptions/constants'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'

import { PriceValue } from 'components/PriceValue'
import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import cancelOfferImage from 'assets/images/cancel-offer-firework.png'
import specialOfferImage from 'assets/images/special-offer-gift.png'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  SubscriptionListType,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledCancelOfferVariant2 as S } from './CancelOfferVariant2.styles'

export const CancelOfferVariant2: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const {
    cohort,
    subscriptions,
    productName,
    oldPrice,
    currentPrice,
    periodQuantity: period,
    currency,
  } = usePurchaseStore()
  const { currentPaymentPageId } = usePageInfo()
  const { handleShowPayment, hasPrices } = usePaywall(
    `${currentPaymentPageId}${search}`,
  )

  useGetPrices({
    screenName: ScreenName.CANCEL_OFFER,
    tags:
      productName === ProductKey.THREE_MONTHS &&
      cohort !== Cohort.CARDIMATE_16_CANCEL
        ? `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX},${SubscriptionTag.ONE_YEAR}`
        : `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX},${SubscriptionTag.SIX_MONTHS}`,
    subscriptionType: SubscriptionListType.PURCHASE,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
    })
  }, [subscriptions])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <S.Firework src={cancelOfferImage} alt="cancel_offer" />
        <S.Title>
          <Trans i18nKey="subscriptions.cancelOffer.title2" />
        </S.Title>
        <S.DiscountContainer>
          <S.DiscountTitle>{t`subscriptions.cancelOffer.discountTitle`}</S.DiscountTitle>
          <S.PressureText>{t`subscriptions.cancelOffer.noPressure`}</S.PressureText>
          <S.PercentContainer>
            <img src={specialOfferImage} alt="special_offer" />
            <S.PercentText>{t`subscriptions.cancelOffer.secretDiscount`}</S.PercentText>
            <S.PercentWrapper>
              <S.CurrentDiscount>
                {t(`subscriptions.cancelOffer.currentDiscount`, {
                  discount: oldPrice.percentOfDiscount,
                })}
              </S.CurrentDiscount>
              <S.OldDiscount>-30%</S.OldDiscount>
            </S.PercentWrapper>
          </S.PercentContainer>
          <S.CostContainer>
            <S.CostText>
              {t(`subscriptions.cancelOffer.costText`, { period })}
            </S.CostText>
            <S.PriceWrapper>
              <S.CurrentPrice>
                <PriceValue value={currentPrice} currency={currency} />
              </S.CurrentPrice>
              <S.OldPrice>
                <PriceValue value={oldPrice.fullPrice} currency={currency} />
              </S.OldPrice>
            </S.PriceWrapper>
          </S.CostContainer>
          <S.Difference>
            {t(`subscriptions.cancelOffer.difference`, {
              discount: oldPrice.amountOfDiscount,
              currencySymbol: CURRENCY_SYMBOLS[currency],
            })}
          </S.Difference>
          <S.Line />
          <S.TotalWrapper>
            <S.TotalText>{t`subscriptions.cancelOffer.total`}</S.TotalText>
            <S.TotalPrice>
              <PriceValue value={currentPrice} currency={currency} />
            </S.TotalPrice>
          </S.TotalWrapper>
        </S.DiscountContainer>
        <S.Button onClick={handleShowPayment}>{t`actions.getMyPlan`}</S.Button>
        <S.SubscriptionDescription>
          <Trans
            i18nKey="subscriptions.cancelOffer.disclaimer"
            values={{
              period,
              price: currentPrice,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              currencyCode: currency.toUpperCase(),
              minimumFractionDigits: 2,
            }}
            components={{
              termsOfUse: <TermsOfUseLink />,
              supportLink: <SupportLink />,
            }}
          />
        </S.SubscriptionDescription>
      </S.Column>
    </S.Wrapper>
  )
}
