import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  OPTION_VALUES,
  OPTION_VALUES_SYMPTOMS_6,
} from 'pages/symptoms/constants'

import { OptionType } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import checkIcon from 'assets/images/check-icon-white.svg'

import { Language } from 'root-constants/common'

import { StyledSymptomsVariant6 as S } from './SymptomsVariant6.styles'

export const SymptomsVariant6: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.symptoms.testosterone', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === OPTION_VALUES.NONE) {
        setAnswers([OPTION_VALUES.NONE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== OPTION_VALUES.NONE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.symptoms.testosterone`}</S.Title>
        <S.Text>{t`onboarding.symptoms.testosteroneRisks`}</S.Text>
        <S.OptionsContainer>
          {OPTION_VALUES_SYMPTOMS_6.map(({ value, text }) => (
            <AnswerWithCheckbox
              {...optionProps}
              theme={answerWithCheckboxTheme.CARDIMATE}
              value={value}
              key={value}
              checked={answers.includes(value)}
              iconSrc={checkIcon}
              margin="0 0 12px"
            >
              {t(text)}
            </AnswerWithCheckbox>
          ))}
        </S.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
