import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import eatingHabitsImage from 'assets/images/eating-habits.webp'

import { StyledEatingHabitsVariant1 as S } from './EatingHabitsVariant1.styles'

export const EatingHabitsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Content>
          <S.Title>{t`onboarding.eatingHabits.title`}</S.Title>
          <S.ImageContainer>
            <img src={eatingHabitsImage} alt="eating-habits" />
          </S.ImageContainer>
        </S.Content>
        <S.StickyButtonContainer>
          <Button
            type="button"
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
