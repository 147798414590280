import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { goTo } from 'browser-history'
import { Language } from 'root-constants/common'
import { HEALTH_CONDITION_OPTION_VALUES } from 'root-constants/options'

import { StyledOverallCondition as S } from './OverallCondition.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const OverallConditionVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === HEALTH_CONDITION_OPTION_VALUES.NONE) {
        setAnswers([HEALTH_CONDITION_OPTION_VALUES.NONE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== HEALTH_CONDITION_OPTION_VALUES.NONE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  const handleContinue = () => {
    dispatch(
      setAnswersAction({
        answers,
        pageId,
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.overallCondition.title', { lng: Language.EN }),
      answers,
      pageName: PAGE_NAME,
    })

    answers.includes(OPTION_VALUES.NONE)
      ? goTo(alternativePagePath)
      : goTo(nextPagePath)
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.overallCondition.title`}</S.Title>
        <S.Text>{t`commonComponents.selectMoreThanOne`}</S.Text>
        <StyledOption.OptionsContainer marginBottom={13}>
          <Option
            {...optionProps}
            value={OPTION_VALUES.OVERWEIGHT}
            checked={answers.includes(OPTION_VALUES.OVERWEIGHT)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.overweight`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.BARELY_ACTIVE}
            checked={answers.includes(OPTION_VALUES.BARELY_ACTIVE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.barelyActive`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.SMOKE}
            checked={answers.includes(OPTION_VALUES.SMOKE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.smoke`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ALCOHOL}
            checked={answers.includes(OPTION_VALUES.ALCOHOL)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.alcohol`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DIET}
            checked={answers.includes(OPTION_VALUES.DIET)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.diet`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.STRESS}
            checked={answers.includes(OPTION_VALUES.STRESS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.overallCondition.stress`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NONE}
            checked={answers.includes(OPTION_VALUES.NONE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`actions.none`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>

        <StickyButtonContainer>
          <Button onClick={handleContinue} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
