import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/user/useUserData'

import { SYMPTOMS_TO_MARKUP } from 'root-constants/common'

export const useUserSymptoms = (maxSize) => {
  const { t } = useTranslation()
  const { symptoms } = useUserData()

  return (
    symptoms
      ?.slice(0, maxSize)
      .map((symptom) => t(SYMPTOMS_TO_MARKUP[symptom]).toLowerCase()) || []
  )
}
