import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledUpsellVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 32px;
  `,
  StatusContainer: styled.div`
    max-width: 360px;
    padding: 16px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;

    strong {
      color: ${({ theme }) => theme.colors.highlightedText};
      font-weight: 900;
    }
  `,
  Button: styled(Button)`
    margin-bottom: 16px;
  `,
  LinkButton: styled(Button)`
    height: auto;
    margin-bottom: 16px;
    background-color: transparent;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration-line: underline;
    background-image: none;
    box-shadow: none;
  `,
  Disclaimer: styled.p`
    margin-bottom: 24px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    strong {
      font-weight: 800;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
