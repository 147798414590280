import React, { forwardRef } from 'react'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { ISubscription } from 'models/subscriptions.model'

import { ProductKey } from 'modules/subscriptions/constants'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { OptionType } from 'components/Option'

import { StyledSelectPlanVariant1 as S } from './SelectPlanBlockVariant1.styles'

type TProps = {
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<ISelectPlanItemProps>
  scrollMargin?: number
  className?: string
}

export const SelectPlanBlockVariant1 = forwardRef<HTMLDivElement, TProps>(
  ({ onSelect, SelectPlanItem, scrollMargin, className }, ref) => {
    const { subscriptions, selectedSubscriptionId } = usePurchaseStore()

    const handleChange = (value) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)
      onSelect(checkedSubscription)
    }

    return (
      <S.Wrapper ref={ref} scrollMargin={scrollMargin} className={className}>
        {subscriptions.map((subscription) => (
          <S.Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
            disabled={subscription.product === ProductKey.ONE_WEEK_SOULDOUT}
          >
            <SelectPlanItem
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </S.Option>
        ))}
      </S.Wrapper>
    )
  },
)
