import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { getEnvironment } from 'helpers/getEnvironment'

import {
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants/common'

export const useLanguage = (): void => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)

  const { isStageEnvironment } = getEnvironment()

  const isInContextActive = isStageEnvironment && isInContextShown

  useEffect(() => {
    const langQueryParam =
      new URL(window.location.href).searchParams.get(LANG_QUERY_PARAM) || ''
    const isSupportedLang = SUPPORTED_LOCALES.includes(langQueryParam)
    const langValue = isSupportedLang ? langQueryParam : INITIAL_LANGUAGE
    const newLangValue = isInContextActive ? Language.IN_CONTEXT : langValue

    dispatch(setLanguageAction(newLangValue))
  }, [dispatch, isInContextActive])
}
