import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { Language } from 'root-constants/common'

import { StyledMonitorBloodPressureVariant2 as S } from './MonitorBloodPressureVariant2.styles'
import { OPTION_VALUES } from './constants'

export const MonitorBloodPressureVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = (answer) => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId,
      }),
    )

    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: t('onboarding.monitorBloodPressure.title2', {
        lng: Language.EN,
      }),
      answers: answer,
      pageName: pageId,
    })

    goTo(
      answer === OPTION_VALUES.NOT_REGULARLY
        ? alternativePagePath
        : nextPagePath,
    )
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.monitorBloodPressure.title2`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.REGULARLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.monitorBloodPressure.regularly2`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NOT_REGULARLY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.monitorBloodPressure.notRegularly`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
