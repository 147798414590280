import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { usePageInfo } from 'providers/PageInfoProvider'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { sendUserConfigAction, setProductName } from 'root-redux/actions/user'

import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useIdleTimer } from 'hooks/ui/useIdleTimer'
import { useUserData } from 'hooks/user/useUserData'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { getDefaultPaymentErrorsState } from 'modules/payment/helpers/getDefaultPaymentErrorsState'
import { getPaymentErrorState } from 'modules/payment/helpers/getPaymentErrorState'

import { replaceHistory } from 'browser-history'
import { Cohort, PaymentMethod, StripeFieldName } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import {
  EMPTY_FIELD_ERROR,
  IDLE_TIME,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from '../../constants'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from '../../redux/actions/common'
import { TCreditCardField, TPaymentErrorState } from '../../types'
import {
  StyledStripePaymentForm as S,
  stripeElementStyle,
} from './StripePaymentForm.styles'

export const StripePaymentForm: React.FC = (props) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const {
    cohort,
    hasCancelOffer,
    threeDSecureIframeUrl,
    screenName,
    productId: productName,
    isFetching,
    isPaymentPopupActive,
    isPaymentProcessingPopupActive,
    error: requestError,
  } = usePurchaseStore()

  const productId = useProductId()
  const { goal } = useUserData()
  const { currentPageId } = usePageInfo()

  const hasErrors = Object.values(errors).some(
    (error) => error.isShown && error.error,
  )

  const hasUntouchedFields = Object.values(errors).some(
    (error) => error.isShown && !error.isTouched,
  )

  const hasUncompletedFields = Object.values(errors).some(
    (field) => !field.isComplete,
  )

  const isFormValid = !hasErrors && !hasUntouchedFields && !hasUncompletedFields
  const hasFilledFields = Object.values(errors).some((field) => !field.isEmpty)

  const handleUserInactivity = useCallback(() => {
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logFortyFiveSecInaction({
      productId,
      goal,
      screenName,
    })

    if (hasCancelOffer && screenName !== ScreenName.CANCEL_OFFER) {
      dispatch(setSubscriptionListAction([]))
      dispatch(setProductName(productName))
      dispatch(
        sendUserConfigAction({
          product_name: productName,
        }),
      )
      replaceHistory({ pathname: PageId.CANCEL_OFFER_DISCOUNT_1, search })
    }
  }, [
    cohort,
    dispatch,
    goal,
    hasCancelOffer,
    pathname,
    productId,
    productName,
    screenName,
    search,
  ])

  useIdleTimer({
    idleTime: IDLE_TIME,
    idleCallback: handleUserInactivity,
    shouldBePaused:
      isPaymentPopupActive ||
      isPaymentProcessingPopupActive ||
      hasFilledFields ||
      cohort !== Cohort.CARDIMATE_16_CANCEL,
  })

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure({ stripe, goal }))
  }, [dispatch, search, stripe, threeDSecureIframeUrl, goal, screenName])

  const handleChange = ({
    fieldName,
    isEmpty,
    hasError,
    isComplete,
    nextElemRef,
  }: {
    fieldName: StripeFieldName
    isEmpty: boolean
    hasError: boolean
    isComplete: boolean
    nextElemRef?: RefObject<TCreditCardField>
  }) => {
    dispatch(resetErrorAction())

    let error = ''

    if (hasError) {
      error = 'is invalid'
    }

    if (isEmpty && fieldName !== StripeFieldName.NAME) {
      error = EMPTY_FIELD_ERROR
    }

    if (nextElemRef && isComplete) {
      nextElemRef.current?.focus()
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: {
        ...prevErrors[fieldName],
        isTouched: true,
        error,
        isComplete,
        isEmpty,
      },
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(resetErrorAction())

    if (hasUntouchedFields) {
      setErrors(getPaymentErrorState(errors))
      return
    }

    if (hasErrors || isFetching || requestError) return

    const card = elements?.getElement(CardNumberElement)

    if (!stripe || !card) return

    eventLogger.logPaymentMethodSelected({
      paymentMethod: PaymentMethod.CREDIT_CARD,
    })
    cardholderNameElemRef.current?.blur()
    dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    dispatch(
      purchaseAction({
        stripe,
        card,
        name,
        paymentPageId: currentPageId,
        goal,
      }),
    )
  }

  return (
    <S.Form onSubmit={handleSubmit} {...props}>
      <S.Label gridArea="cardNumberLabel">{t`payment.stripe.card`}</S.Label>
      <S.CardNumberElement
        onReady={(elem) => {
          elem.focus()
          cardNumberElemRef.current = elem
        }}
        options={{
          showIcon: false,
          placeholder: '1234 1234 1234 1234',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
      />

      <S.Label gridArea="cardExpiryLabel">{t`payment.stripe.date`}</S.Label>
      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t`payment.stripe.datePlaceholder`,
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
      />

      <S.Label gridArea="cardCvcLabel">{t`payment.stripe.cvv`}</S.Label>
      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: '111',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
      />

      <S.Label gridArea="cardholderLabel">{t`payment.stripe.name`}</S.Label>
      <S.CardholderInput
        ref={cardholderNameElemRef}
        type="text"
        placeholder={t`payment.stripe.placeholder`}
        onChange={(e) => {
          const value = e.target.value.trim()
          setName(value)

          handleChange({
            fieldName: StripeFieldName.NAME,
            isEmpty: !value,
            hasError: false,
            isComplete: true,
          })
        }}
      />
      <S.SubmitButton
        type="submit"
        disabled={!stripe || !isFormValid || isFetching || requestError}
      >
        {t`actions.continue`}
      </S.SubmitButton>
    </S.Form>
  )
}
