import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import femaleDoctor from 'assets/images/social-proof-female-doctor.jpg'
import maleDoctor from 'assets/images/social-proof-male-doctor.jpg'

import { StyledSocialProofVariant1 as S } from './SocialProofVariant1.styles'

export const SocialProofVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Img src={isMale ? maleDoctor : femaleDoctor} alt="doctor" />
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.socialProof.welcomeToCardi" />
        </S.Title>
        <S.Text>
          <Trans i18nKey="onboarding.socialProof.ourMission" />
        </S.Text>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue('')}
            theme={buttonTheme.CARDIMATE}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
