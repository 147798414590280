import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { PriceValue } from 'components/PriceValue'

import guide from 'assets/images/upsell-benefit-guide.png'
import management from 'assets/images/upsell-benefit-management.png'
import playlist from 'assets/images/upsell-benefit-playlist.png'

import { StyledUpsellBenefits as S } from './UpsellBenefits.styles'

export const UpsellBenefits: React.FC = () => {
  const { t } = useTranslation()
  const { currentPrice, currency } = usePurchaseStore()

  return (
    <S.Wrapper>
      <S.BenefitContainer>
        <S.Image src={playlist} alt="playlist" />
        <S.Text>{t`upsell.benefits.playlists`}</S.Text>
      </S.BenefitContainer>

      <S.BenefitContainer>
        <S.Image src={management} alt="management" />
        <S.Text>{t`upsell.benefits.management`}</S.Text>
      </S.BenefitContainer>

      <S.BenefitContainer>
        <S.Image src={guide} alt="guide" />
        <S.Text>{t`upsell.benefits.guide`}</S.Text>
      </S.BenefitContainer>

      <S.Price>
        <PriceValue
          value={currentPrice}
          currency={currency}
          i18nKey="upsell.benefits.price"
        />
      </S.Price>
    </S.Wrapper>
  )
}
