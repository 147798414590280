import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { Language } from 'root-constants/common'

import { StyledPhysicalActivityVariant3 as S } from './PhysicalActivityVariant3.styles'
import { OPTION_VALUES } from './constants'

export const PhysicalActivityVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.physicalActivity.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.physicalActivity.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.LIGHT}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>
              <S.ActivityLevel>{t`onboarding.physicalActivity.lightlyActive`}</S.ActivityLevel>
              <S.Description>
                {t`onboarding.physicalActivity.lightlyDescription`}
              </S.Description>
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.MODERATE}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>
              <S.ActivityLevel>{t`onboarding.physicalActivity.moderatelyActive`}</S.ActivityLevel>
              <S.Description>
                {t`onboarding.physicalActivity.moderatelyDescription`}
              </S.Description>
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.HIGH}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>
              <S.ActivityLevel>{t`onboarding.physicalActivity.veryActive`}</S.ActivityLevel>
              <S.Description>
                {t`onboarding.physicalActivity.veryActiveDescription`}
              </S.Description>
            </S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
