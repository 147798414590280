import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectTaxAmount } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/common/useVatInfo'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledPlanItemLong as S } from './PlanItemLong.styles'
import { BILLING_CYCLE_CONTEXTS } from './constants'

export const PlanItemLong: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    product,
    mainPrices: { periodQuantity, daily, monthly, oldPrices, periodName },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)

  const hasIncludedVAT = useVatInfo()

  const oldDailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: oldPrices.beforeCustomDiscount.daily,
    taxAmount,
  })

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  const oldPricePerMonthWithoutTaxes = getPriceWithoutTaxes({
    price: oldPrices.beforeCustomDiscount.monthly,
    taxAmount,
  })

  const pricePerMonthWithoutTaxes = getPriceWithoutTaxes({
    price: monthly,
    taxAmount,
  })

  return (
    <S.Wrapper>
      <S.PlanItem
        data-is-selected={isSelected}
        data-is-default={isDefault}
        data-text={t('subscriptions.springOffer', {
          discountAmount: oldPrices.beforeCustomDiscount.percentOfDiscount,
        })}
      >
        <S.Title>
          {t('subscriptions.planPeriodV3', {
            periodQuantity,
          })}
        </S.Title>
        <S.Subtitle>{t(`subscriptions.${product}`)}</S.Subtitle>
        <S.OldPriceDaily>
          {CURRENCY_SYMBOLS[currency]}
          {hasIncludedVAT
            ? oldDailyPriceWithoutTaxes
            : oldPrices.beforeCustomDiscount.daily.toFixed(2)}
        </S.OldPriceDaily>
        <S.PlanPriceDaily>
          {CURRENCY_SYMBOLS[currency]}
          {hasIncludedVAT ? dailyPriceWithoutTaxes : daily.toFixed(2)}{' '}
          <span>/ {t`payment.planBlock.day`}</span>
        </S.PlanPriceDaily>
        <S.TotalPriceWrapper>
          <S.CommonText>{t`payment.paymentSummary.total`}:</S.CommonText>
          <S.OldPriceMonthly>
            {CURRENCY_SYMBOLS[currency]}
            {hasIncludedVAT
              ? oldPricePerMonthWithoutTaxes
              : oldPrices.beforeCustomDiscount.monthly.toFixed(2)}
          </S.OldPriceMonthly>
          <S.PlanPriceMonthly>
            {CURRENCY_SYMBOLS[currency]}
            {hasIncludedVAT
              ? pricePerMonthWithoutTaxes
              : monthly.toFixed(2)} / {t`payment.planBlock.month`}
          </S.PlanPriceMonthly>
        </S.TotalPriceWrapper>
        <S.CommonText>
          {t(`subscriptions.billingCycle`, {
            context: BILLING_CYCLE_CONTEXTS[periodQuantity],
            count: periodQuantity,
            periodName,
          })}
        </S.CommonText>
      </S.PlanItem>
    </S.Wrapper>
  )
}
