import { PaymentInstrumentType } from '@primer-io/checkout-web'

import { Cohort, PaymentMethod } from 'root-constants/common'

export const EMPTY_FIELD_ERROR = `can't be blank`
export const DEFAULT_CARDHOLDER_NAME = ' '

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'
export const IDLE_TIME = 45000

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const enum StripeDeclineReason {
  STOLEN_CARD = 'stolen_card',
  LOST_CARD = 'lost_card',
  GENERIC_DECLINE_ERROR = 'generic_decline',
}

export const NO_TRIAL_NO_DISCOUNT_COHORTS: string[] = [
  Cohort.CARDIMATE_2,
  Cohort.CARDIMATE_11,
]

export const NO_PAYPAL_COHORTS: string[] = [
  Cohort.CARDIMATE_15,
  Cohort.CARDIMATE_18,
  Cohort.CARDIMATE_19,
  Cohort.CARDIMATE_19_FEED,
  Cohort.CARDIMATE_19_UPDATE,
  Cohort.CARDIMATE_19_NP,
]

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
}

export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'payment.paymentWaitingModal.insufficientFunds',
  PAYMENT_FAILED: 'payment.paymentWaitingModal.insufficientFunds',
  DECLINED: 'payment.paymentWaitingModal.declinedError',
  AUTHENTICATION_REQUIRED: 'payment.paymentWaitingModal.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'payment.paymentWaitingModal.withdrawLimit',
  EXPIRED_CARD: 'payment.paymentWaitingModal.expiredCard',
  COMMON_ERROR: 'commonComponents.commonError',
  ERROR: 'commonComponents.commonError',
  INVALID_CARD_NUMBER: 'commonComponents.commonError',
  LOST_OR_STOLEN_CARD: 'commonComponents.commonError',
  SUSPECTED_FRAUD: 'commonComponents.commonError',
  UNKNOWN: 'commonComponents.commonError',
  REFER_TO_CARD_ISSUER: 'commonComponents.commonError',
  DO_NOT_HONOR: 'commonComponents.commonError',
  ISSUER_TEMPORARILY_UNAVAILABLE: 'commonComponents.commonError',
}

export const PRIMER_PAY_PAL_ID = 'paypal-button'

export const PRIMER_PAYMENT_METHODS_MAP = {
  [PaymentInstrumentType.CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentInstrumentType.APPLE_PAY]: PaymentMethod.APPLE_PAY,
  [PaymentInstrumentType.GOOGLE_PAY]: PaymentMethod.GOOGLE_PAY,
  [PaymentInstrumentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PaymentMethod.PAYPAL,
}

export const PRIMER_PAYMENT_METHOD_ACTIONS = {
  SELECTED: 'PAYMENT_METHOD_SELECTED',
  UNSELECTED: 'PAYMENT_METHOD_UNSELECTED',
}
export const TRIAL_COHORTS_WITH_TOTAL_AMOUNT: string[] = [
  Cohort.CARDIMATE_9,
  Cohort.CARDIMATE_14,
  Cohort.CARDIMATE_15,
  Cohort.CARDIMATE_17,
  Cohort.CARDIMATE_20,
  Cohort.CARDIMATE_21,
]

const enum CountryCode {
  ARGENTINA = 'ar',
  COLOMBIA = 'co',
  PERU = 'pe',
  CHILE = 'cl',
  DOMINICAN_REPUBLIC = 'do',
  URUGUAY = 'uy',
  ECUADOR = 'ec',
  GUATEMALA = 'gt',
  VENEZUELA = 've',
  PARAGUAY = 'py',
  BOLIVIA = 'bo',
  NICARAGUA = 'ni',
  HONDURAS = 'hn',
  COSTA_RICA = 'cr',
  PANAMA = 'pa',
  EL_SALVADOR = 'sv',
  BRAZIL = 'br',
  INDIA = 'in',
  PHILIPPINES = 'ph',
  SOUTH_AFRICA = 'za',
  NIGERIA = 'ng',
  KENYA = 'ke',
  ZAMBIA = 'zm',
  TANZANIA = 'tz',
  ETHIOPIA = 'et',
  ZIMBABWE = 'zw',
  SENEGAL = 'sn',
  NAMIBIA = 'na',
}

export const COUNTRIES_WITHOUT_PAYPAL: string[] = [
  CountryCode.ARGENTINA,
  CountryCode.COLOMBIA,
  CountryCode.PERU,
  CountryCode.CHILE,
  CountryCode.DOMINICAN_REPUBLIC,
  CountryCode.URUGUAY,
  CountryCode.ECUADOR,
  CountryCode.GUATEMALA,
  CountryCode.VENEZUELA,
  CountryCode.PARAGUAY,
  CountryCode.BOLIVIA,
  CountryCode.NICARAGUA,
  CountryCode.HONDURAS,
  CountryCode.COSTA_RICA,
  CountryCode.PANAMA,
  CountryCode.EL_SALVADOR,
  CountryCode.BRAZIL,
  CountryCode.INDIA,
  CountryCode.PHILIPPINES,
  CountryCode.SOUTH_AFRICA,
  CountryCode.NIGERIA,
  CountryCode.KENYA,
  CountryCode.ZAMBIA,
  CountryCode.TANZANIA,
  CountryCode.ETHIOPIA,
  CountryCode.ZIMBABWE,
  CountryCode.SENEGAL,
  CountryCode.NAMIBIA,
]
