import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledSymptoms as S } from './Symptoms.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const SymptomsVariant2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: CustomPageId.SYMPTOMS,
    pageName: PAGE_NAME,
    question: t('onboarding.symptoms.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: CustomPageId.SYMPTOMS,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked && value === OPTION_VALUES.NONE) {
        setAnswers([OPTION_VALUES.NONE])
        return
      }

      if (isChecked) {
        const newValues = [...answers, value].filter(
          (item) => item !== OPTION_VALUES.NONE,
        )
        setAnswers(newValues)
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          {t('onboarding.symptoms.title', { context: cohortToUse })}
        </S.Title>
        <S.Text>
          {t('onboarding.symptoms.subtitle', { context: cohortToUse })}
        </S.Text>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.FATIGUE}
            checked={answers.includes(OPTION_VALUES.FATIGUE)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.fatigue`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.HEADACHES}
            checked={answers.includes(OPTION_VALUES.HEADACHES)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.headaches`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.VISION_PROBLEMS}
            checked={answers.includes(OPTION_VALUES.VISION_PROBLEMS)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.visionProblems`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DIFFICULTY_BREATHING}
            checked={answers.includes(OPTION_VALUES.DIFFICULTY_BREATHING)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.difficultyBreathing`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DIFFICULTY_CONCENTRATING}
            checked={answers.includes(OPTION_VALUES.DIFFICULTY_CONCENTRATING)}
          >
            <QuestionButton data-has-check-icon>
              {t`onboarding.symptoms.difficultyConcentrating`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NONE}
            checked={answers.includes(OPTION_VALUES.NONE)}
          >
            <QuestionButton data-has-none-button-check-icon>
              {t`actions.none`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButtonContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
